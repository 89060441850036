import DestinationIcon from 'components/icons/DestinationIcon';
import DiscoverIcon from 'components/icons/DiscoverIcon';
import FavoriteIcon from 'components/icons/FavoriteIcon';
import MyVadrouillesIcon from 'components/icons/MyVadrouillesIcon';
import { type LinkProps } from 'components/menu/NavLink';
import NavAccountContainer from 'components/menu/account/NavAccountContainer';
import LoginLink from './LoginLink';


const ICON_SIZE = 30;

const navLinks: LinkProps[] = [
    {
        labelT: 'pages.discover.layout.menu',
        href: '/',
        icon: <DiscoverIcon size={ICON_SIZE} />,
        display: {
            mobile: true,
            desktop: false
        }
    },
    {
        labelT: 'models.establishment.layout.menu',
        href: '/establishments',
        icon: <DestinationIcon color={'childTertiary'} size={ICON_SIZE}/>,
        display: {
            mobile: true,
            desktop: true
        }
    },
    {
        labelT: 'layout.menu.my_vadrouilles',
        href: '/my-vadrouilles',
        component: LoginLink,
        icon: <MyVadrouillesIcon size={ICON_SIZE}/>,
        display: {
            mobile: true,
            desktop: true
        },
        labelTypographyProps: {
            fontSize: { xs: 12, md: 16 }
        },
        sx: {
            minWidth: { xs: 94, md: 138 }
        }
    },
    {
        labelT: 'layout.menu.favorites',
        href: '/favorites',
        component: LoginLink,
        icon: <FavoriteIcon size={ICON_SIZE}/>,
        display: {
            mobile: true,
            desktop: true
        }
    },
    {
        href: '/login',
        component: NavAccountContainer
    }
];

export {
    navLinks
};

