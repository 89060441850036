import Box, { type BoxProps } from '@mui/material/Box';
import { NavDesktopLink, type NavDesktopLinkProps } from 'components/menu/navbar/desktop/NavBarDesktop';
import { type NavLinkProps } from 'components/menu/NavLink';
import useApi from 'hooks/useApi';
import { useEffect, useState, type ReactElement } from 'react';

interface LoginLinkProps extends NavLinkProps {
    children?: ReactElement,
    showLabel?: boolean
}

const LoginLink = ({ children, href = '/favorites', showLabel, ...props }: LoginLinkProps) => {
    const { authenticated, loading, showLoginHint } = useApi();
    const [menuWrapperProps, setMenuWrapperProps] = useState<Partial<BoxProps & NavDesktopLinkProps> | undefined>(undefined);
    // Permet d'éviter la perte de ref sur le parent
    useEffect(() => {
        setMenuWrapperProps(od => !authenticated ? ({
            onClick: () => showLoginHint({ redirectUri: encodeURIComponent(href) })
        }) : loading ? od : ({
            href: href
        }));
    }, [authenticated, loading, showLoginHint, href]);

    return (
        <>
            <Box
                href={''}
                component={NavDesktopLink}
                {...props}
                {...menuWrapperProps}
            />

        </>
    );
};

export default LoginLink;
export type {
    LoginLinkProps
};

