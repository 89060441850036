import { useTheme } from '@mui/material/styles';

interface IconProps {
    size?: number | string;
}

const BackpackIcon = ({ size, ...props }: IconProps) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 24}
            height={size || 24}
            viewBox="0 0 24 24"
            fill={theme.palette.primary.main}
            {...props}
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M15.93,13.18h-7.62c-.62,0-1.12,.5-1.12,1.12v3.69c0,1.22,1,2.22,2.22,2.22h5.6c1.06,0,1.93-.86,1.93-1.93v-4.11c0-.55-.45-1-1-1Zm-.03,5.11c0,.49-.4,.9-.9,.9h-5.6c-.66,0-1.19-.53-1.19-1.19v-3.69c0-.05,.04-.09,.09-.09l7.59-.03v4.11Z"/>
            <path d="M21.08,12.89h-1.46v-3.72c0-.32-.07-.63-.17-.93,.12-.38,.2-.78,.2-1.2v-1.2c0-1.4-1.14-2.54-2.54-2.54h-1.73c-.18-1.3-1.28-2.3-2.62-2.3h-1.51c-1.34,0-2.44,1-2.62,2.3h-1.73c-1.4,0-2.54,1.14-2.54,2.54v1.06c0,.45,.09,.88,.22,1.28-.12,.31-.19,.64-.19,.98v3.72h-1.46c-.49,0-.89,.4-.89,.89v5.44c0,1.24,1.01,2.25,2.25,2.25h.25c.06,0,.11-.02,.16-.03,.46,.92,1.4,1.56,2.5,1.56h9.62c1.1,0,2.04-.64,2.5-1.56,.06,.01,.11,.03,.17,.03h.25c1.24,0,2.25-1.01,2.25-2.25v-5.44c0-.49-.4-.89-.89-.89ZM11.24,2.03h1.51c.77,0,1.42,.54,1.59,1.27h-4.68c.16-.73,.81-1.27,1.58-1.27Zm-5.86,3.81c0-.83,.68-1.51,1.51-1.51h10.22c.83,0,1.51,.68,1.51,1.51v1.2c0,1.6-1.26,2.9-2.84,3v-1.31c0-.28-.23-.52-.52-.52s-.52,.23-.52,.52v1.33h-4.98v-1.29c0-.28-.23-.52-.52-.52s-.52,.23-.52,.52v1.29h-.19c-1.74,0-3.16-1.42-3.16-3.16v-1.06Zm-1,14.6h-.11c-.67,0-1.22-.55-1.22-1.22v-5.31h1.32v6.28s0,.04,0,.06v.19Zm12.42,1.53H7.19c-.96,0-1.74-.76-1.77-1.72v-6.47s0-.02,0-.03v-4.09c.77,.87,1.88,1.43,3.13,1.43h.19v.23c0,.28,.23,.52,.52,.52s.52-.23,.52-.52v-.23h4.98v.23c0,.28,.23,.52,.52,.52s.52-.23,.52-.52v-.25c1.11-.05,2.11-.55,2.81-1.32v4.03s0,0,0,0v6.43c-.01,.97-.8,1.76-1.77,1.76Zm4.13-2.75c0,.67-.55,1.22-1.22,1.22h-.11v-.23s0-.01,0-.02v-6.28h1.33v5.31Z"/>
        </svg>
    );
};

export default BackpackIcon;
export { BackpackIcon };

