import { useTheme } from '@mui/material/styles';

interface IconProps {
    size?: number | string;
}

const FVIcon = ({ size, ...props }: IconProps) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 24}
            height={size || 24}
            viewBox="0 0 24 24"
            fill={theme.palette.primary.main}
            {...props}
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M15.58,1.38c-2.36-.94-5.11-.09-6.53,2.01-.09,.14-.1,.31-.02,.46,.08,.15,.22,.23,.39,.23,.15,0,.29-.08,.37-.21,1.13-1.77,3.38-2.51,5.35-1.76,1.29,.43,2.19,1.17,2.67,2.18,.87,1.82,.11,3.96,.1,3.98l-3.3,8.96c-.03,.08-.09,.12-.18,.13h0c-.08,0-.14-.04-.18-.12l-3.56-8.1c-.24-.56-.64-1.04-1.14-1.4-.58-.38-1.25-.57-1.93-.53l-2.37-.02h0c-.08,0-.16,.03-.22,.09-.06,.06-.09,.13-.09,.22,0,.16,.1,.3,.25,.34l.71,.22c.05,.02,.1,.06,.12,.11l6.43,14.56c.07,.16,.23,.26,.4,.26h.22c.18,0,.35-.12,.41-.29l5.29-14.35h0c.02-.06,1.55-5.33-3.22-6.98Z"/>
            <path d="M15.15,6.54c0,.85-.69,1.54-1.54,1.54-.85,0-1.54-.69-1.54-1.54s.69-1.54,1.54-1.54c0,0,0,0,0,0,.85,0,1.53,.69,1.53,1.54" fill={theme.palette.tertiary.main}/>
        </svg>
    );
};

export default FVIcon;

