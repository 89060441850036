import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import WarningIcon from '@mui/icons-material/WarningAmber';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import FormSearchSelectField, {
    type FormSearchSelectFieldProps,
    type OptionProps
} from 'components/form/FormSearchSelectField';
import BackpackIcon from 'components/icons/BackpackIcon';
import DestinationIcon from 'components/icons/DestinationIcon';
import PictoIcon from 'components/icons/FVIcon';
import GeolocationIcon from 'components/icons/GeolocationIcon';
import MarkerIcon from 'components/icons/MarkerIcon';
import SearchListbox from 'components/menu/search/SearchListbox';
import useAlert from 'hooks/useAlert';
import useTranslation from 'hooks/useTranslation';
import { type ElementType, Fragment, memo, useCallback, useMemo, useRef, useState } from 'react';
import useGeolocation from 'react-hook-geolocation';
import { type PaginationUrlProps } from 'services/api';
import LinkOverlay from 'shared/components/utils/LinkOverlay';
import { useBoolean, useIsMounted, useIsomorphicLayoutEffect } from 'usehooks-ts';
import FilterButton from './FilterButton';
import { useQueryParams } from 'contexts/NavMenuContext';

interface SearchInputProps extends Partial<FormSearchSelectFieldProps> {
    expended?: boolean,
    onFilterClick?: (_: any) => void,
    filtersAvailable?: boolean,
    StartIcon?: ElementType
}

const SearchInput = ({
    value,
    onChange,
    onFilterClick,
    filtersAvailable = true,
    expended,
    sx,
    StartIcon = PictoIcon,
    startAdornment, ...props
}: SearchInputProps) => {
    const { t } = useTranslation();
    const isMounted = useIsMounted();
    const open = useBoolean(false);
    const ref = useRef<HTMLInputElement>(null);
    const [queryParams, setQueryParams] = useQueryParams(store => store);


    const getOptionIcon = useCallback((type: string) => {
        switch (type?.toLocaleLowerCase()) {
            case 'target':
                return <GeolocationIcon/>;
            case 'city':
                return <MarkerIcon/>;
            case 'destination':
                return <MarkerIcon/>;
            case 'establishment':
                return <DestinationIcon color={'primary'}/>;
            default:
                return <BackpackIcon/>;
        }
    }, []);

    const triggerAroundMeDialog = useBoolean(false);

    const onGeolocationUpdate = useCallback((_geoloc: any) => {
        const { latitude, longitude, accuracy } = _geoloc;

        triggerAroundMeDialog.setFalse();
        const toBeRemoved = ['ne_lat', 'ne_lng', 'sw_lat', 'sw_lng']
            .filter(key => queryParams?.[key])
            .map(key => ({ [key]: { [Object.keys(queryParams[key])[0]]: false } }))
            .reduce((a, c) => ({ ...a, ...c }), {});
        if (latitude && longitude) {
            return onChange ? onChange(null, ...Object.entries({
                latitude,
                longitude
                // ...toBeRemoved
            }).map(([k, v]) => ({ [k]: v }))) : Promise.resolve();
        }
    }, [triggerAroundMeDialog, onChange, queryParams, setQueryParams]);

    const { error: geolocError } = useGeolocation({}, onGeolocationUpdate, triggerAroundMeDialog.value);

    useIsomorphicLayoutEffect(() => {
        //reset la demande a chaque frame après toutes les autres interactions
        if (isMounted()) {
            triggerAroundMeDialog.setFalse();
        }
    });

    const { alert } = useAlert();
    const alertGeolocUnavailable = useCallback(() => {
        alert({
            title: t('layout.geoloc.error.title'),
            text: t('layout.geoloc.error.text'),
            status: 'error'
        });
    }, [alert, t]);

    const [localInputValue, setLocalInputValue] = useState<string>('');
    const handleOnChange = useCallback((event: any) => {
        if (event.target?.value) {
            const { callback, ListItemProps, ...pureValue } = event.target.value;
            if (callback) {
                return callback(event, pureValue, onChange);
            }
            return onChange ? onChange(event, pureValue) : Promise.resolve();
        }
        setLocalInputValue('');
        return Promise.resolve();
    }, [onChange]);

    const onInputChange = useCallback((newValue: string) => {
        setLocalInputValue(newValue);
    }, []);

    const handleFilterClick = useCallback((_: any) => {
        open.setFalse();
        return onFilterClick ? onFilterClick(_) : null;
    }, [onFilterClick, open]);

    const aroundMeOption = useMemo(() => ({
        id: 0,
        label: '',
        name: t('layout.form.actions.search_variants.around_me'),
        type: 'target',
        callback: triggerAroundMeDialog.setTrue,
        ListItemProps: {
            key: -1,
            component: ListItemButton,
            secondaryAction: (isMounted() && !!geolocError) ? (
                <IconButton color={'warning'} onClick={alertGeolocUnavailable}>
                    <WarningIcon/>
                </IconButton>
            ) : null
        }
    }), [triggerAroundMeDialog, geolocError, isMounted, t, alertGeolocUnavailable]);

    const valueTransformer = useCallback((inputValue: OptionProps) => {
        return open?.value ? inputValue : { id: 0, name: '' } as OptionProps;
    }, [open]);

    const autocompleteProps = useMemo(() => ({
        autoHighlight: true,
        autoSelect: false,
        autoComplete: true,
        multiple: false,
        freeSolo: false,
        forcePopupIcon: false,
        blurOnSelect: true,
        //disableClearable: true,
        openOnFocus: true,
        //clearIcon: '',
        renderOption: (liProps: any, { type, id, name, ListItemProps }: any) => {
            return (
                <Fragment key={id}>
                    <ListItem
                        {...liProps}
                        {...ListItemProps}
                        divider={true}
                        sx={{
                            backgroundColor: 'transparent',
                            ['&.Mui-focused, &[aria-selected="true"]']: {
                                backgroundColor: 'transparent!important'
                            },
                            ['&:hover, &.Mui-focused:hover, &[aria-selected="true"]:hover']: {
                                backgroundColor: 'rgba(0,0,0,0.16)'
                            }
                        }}
                    >
                        <ListItemIcon sx={{ minWidth: 38 }}>{getOptionIcon(type)}
                        </ListItemIcon>
                        <ListItemText primary={name}/>
                    </ListItem>
                </Fragment>
            );
        },
        ListboxComponent: SearchListbox,
        open: open.value,
        onOpen: open.setTrue,
        onClose: open.setFalse,
        noOptionsText: (
            <ListItem key={0}>
                <ListItemText primary={t('layout.form.state.no_result')}/>
            </ListItem>
        ),
        loadingText: (
            <ListItem key={0}>
                <ListItemText primary={t('layout.form.state.loading')}/>
            </ListItem>
        )
    }), [t, open, getOptionIcon]);

    const paginate = useCallback(({ url }: PaginationUrlProps) => `${url}`, []);

    return (
        <>
            <FormSearchSelectField
                AutocompleteProps={autocompleteProps}
                onChange={handleOnChange}
                type={'text'}
                name={'search'}
                inputMode={'search'}
                placeholder={t('layout.form.actions.search')}
                autoFocus={open.value}
                paginate={paginate}
                forwardPaginateArgs={true}
                {...props}
                fixedOptions={localInputValue.length > 0 ? [] : [aroundMeOption]}
                valueTransformer={valueTransformer}
                value={value || ''}
                onInputChange={onInputChange}
                startAdornment={(
                    <>
                        {startAdornment ? startAdornment : (
                            <SvgIcon
                                component={open.value ? ArrowBackIcon : StartIcon}
                                onClick={open.toggle}
                            />
                        )}
                    </>
                )}
                endAdornment={filtersAvailable ? (
                    <>
                        <Stack
                            direction={'row'}
                            sx={{ position: 'relative' }}
                            divider={
                                <Divider
                                    orientation={'vertical'}
                                    flexItem={true}
                                    variant={'middle'}
                                    light={true}
                                    sx={{
                                        display: 'none'
                                    }}
                                />
                            }>
                            <></>
                            <LinkOverlay>
                                <FilterButton onClick={handleFilterClick}/>
                            </LinkOverlay>
                        </Stack>
                    </>
                ) : undefined}
                FormControlProps={{
                    hiddenLabel: true,
                    sx: {
                        mb: 0
                    }
                }}  
                TextFieldProps={{
                    autoComplete: 'off',
                    autoFocus: false
                }}
                sx={{
                    backgroundColor: 'neutral.main',
                    borderRadius: 3,
                    ['&.Mui-focused .MuiOutlinedInput-notchedOutline, &.Mui-focused:hover .MuiOutlinedInput-notchedOutline']: {
                        borderWidth: 1
                    },
                    zIndex: theme => theme.zIndex.drawer + 1,
                    ...sx
                }}
                autoComplete={'off'}
                ref={ref}
            />

        </>
    );
};

const MemoizedSearchInput = memo(SearchInput);

export default MemoizedSearchInput;
export type {
    SearchInputProps
};

