import AppBar, { type AppBarProps } from '@mui/material/AppBar';
import BottomNavigationAction, { type BottomNavigationActionProps } from '@mui/material/BottomNavigationAction';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import { useTheme, type Theme } from '@mui/material/styles';
import NavLink, { type LinkProps, type NavLinkProps } from 'components/menu/NavLink';
import { type NavBarExtendFromProps } from 'components/menu/navbar/NavBarExtendFrom';
import NavSearch from 'components/menu/search/NavSearch';
import useImmersivePage from 'hooks/useImmersivePage';
import useTranslation from 'hooks/useTranslation';
import { type LinkProps as NextLinkProps } from 'next/link';
import { forwardRef, type ForwardedRef, type ReactElement } from 'react';
import { useElementSize } from 'usehooks-ts';
import NavLogo from '../NavLogo';


interface NavDesktopLinkProps extends Partial<BottomNavigationActionProps & any> {
    labelT?: string,
    labelTypographyProps?: TypographyProps,
    href: NextLinkProps['href'],
    display?: { mobile: boolean },
    NavLinkProps?: NavLinkProps
}
const NavDesktopLink = ({ labelT, labelTypographyProps, href, display = { mobile: true }, children, icon, sx, NavLinkProps, itemProps, ...props }: NavDesktopLinkProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const { t } = useTranslation();
    const [linkRef, { height }] = useElementSize();
    return (
        <NavLink 
            href={href} 
            passHref={true} 
            ref={linkRef as any}
            sx={{ 
                flex: 1,
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'stretch',
                textAlign: 'center',
                ...itemProps?.sx
            }}
        >
            <BottomNavigationAction
                showLabel={true}
                icon={icon}
                label={<Typography fontSize={12} {...labelTypographyProps}>{labelT ? t(labelT) : ''}</Typography>}
                sx={{ 
                    pt: 0, 
                    height: height, 
                    ...sx 
                }}
                {...props}
                ref={ref}
            />
        </NavLink>
    );
};

const NavDesktopLinkWithRef = forwardRef(NavDesktopLink) as
    (p: NavDesktopLinkProps & { ref?: ForwardedRef<HTMLButtonElement> }) => ReactElement;



interface NavBarDesktopProps extends Partial<AppBarProps & NavBarExtendFromProps> {
    links: LinkProps[],
    withSearchFilters: boolean
}

const NavBarDesktop = ({ links, withSearchFilters, AppBarProps, ToolbarProps, ...props }: NavBarDesktopProps, ref: ForwardedRef<any>) => {
    const theme: Theme = useTheme();
    const [parentRef, { height }] = useElementSize();

    const { establishmentId } = useImmersivePage();

    return (
        <Paper
            elevation={0}
            sx={{ zIndex: theme.zIndex.appBar }}
        >
            <AppBar
                color={'neutral'}
                position={'fixed'}
                {...AppBarProps}
                {...props}
                ref={ref}
            >
                <Toolbar
                    {...ToolbarProps}
                    sx={{
                        width: '100%',
                        minHeight: '0!important',
                        px: '0px!important',
                        elevation: 0,
                        ...ToolbarProps?.sx
                    }}
                    {...ToolbarProps}
                    ref={parentRef}
                >
                    <Stack
                        direction={'row'}
                        spacing={2}
                        alignItems={'center'}
                        justifyContent={'space-around'}
                        flex={1}
                        px={0}
                    >
                        <Box>
                            <NavLogo extended/>
                        </Box>
                        <Box flex={1}>
                            <NavSearch
                                hidden={!establishmentId} //TODO: recherche
                                withSearchFilters={withSearchFilters}
                            />
                        </Box>
                        <Stack
                            flex={0}
                            direction={'row'}
                            alignItems={'stretch'}
                            justifyContent={'flex-end'}
                            spacing={0}
                        >
                            {links.filter(({ display = { desktop: true } }) => display.desktop).map(({
                                href,
                                icon,
                                display,
                                component,
                                ...args
                            }, index) => (
                                <Box component={component || NavDesktopLinkWithRef}
                                    showLabel={true}
                                    key={href}
                                    href={href}
                                    display={display}
                                    icon={icon}
                                    {...args}
                                />
                            ))}
                        </Stack>
                    </Stack>
                </Toolbar>
            </AppBar>
            <Toolbar sx={{ height: height }} />
        </Paper>
    );
};

const NavBarDesktopWithRef = forwardRef(NavBarDesktop) as
    (p: NavBarDesktopProps & { ref?: ForwardedRef<any> }) => ReactElement;

export default NavBarDesktopWithRef;
export {
    NavBarDesktopWithRef as NavBarDesktop,
    NavDesktopLinkWithRef as NavDesktopLink
};
export type {
    NavBarDesktopProps,
    NavDesktopLinkProps
};

