import Fade from '@mui/material/Fade';
import Typography, { type TypographyProps } from '@mui/material/Typography';
import formatDuration from 'date-fns/formatDuration';
import { intervalToDuration } from 'date-fns/fp';
import isAfter from 'date-fns/isAfter';
import parse from 'date-fns/parse';
import { forwardRef, useCallback, type ForwardedRef, type ReactElement } from 'react';
import useSWR from 'swr';

const DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';
const DATE_FORMAT_TIMEZONE = `${DATE_FORMAT} x`;



const zeroPad = (num?: number) => String(num).padStart(2, '0');
const getStringDuration = (duration: Duration) => formatDuration(duration, {
    format: ['minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
        formatDistance: (_token, count) => zeroPad(count)
    }
});

interface NavCartTimerProps extends TypographyProps {
    deadline: string,
    referenceDate?: any,
    onTimerEnd?: () => void
}

const NavCartTimer = ({
    deadline,
    referenceDate = new Date(),
    sx,
    onTimerEnd = Promise.resolve,
    ...props
}: NavCartTimerProps, ref: ForwardedRef<HTMLElement>) => {
    const fetcher = useCallback(({ deadline: d, now: n = Date.now() }: { deadline: string, now: any }) => {
        const st = parse(`${d} Z`, DATE_FORMAT_TIMEZONE, referenceDate);
        if (isAfter(n, st)) { throw new Error('');}
        const int = {
            start: isAfter(n, st) ? n : st,
            end: n
        } as Interval;

        return intervalToDuration(int);
    }, [referenceDate]);

    const { data: duration } = useSWR<Duration>({ deadline }, fetcher, {
        fallbackData: intervalToDuration({ start: 0, end: 0 }),
        revalidateOnMount: true,
        revalidateIfStale: true,
        revalidateOnFocus: true,
        revalidateOnReconnect: false,
        refreshInterval: od => (od) ? 300 : 0,
        onError: () => onTimerEnd(),
        shouldRetryOnError: false,
        dedupingInterval: 0
    });

    return (
        <Typography
            {...props}
            sx={{
                position: 'absolute',
                bottom: { xs: -6, md: -14 },
                left: { xs: '50%', md: 'calc( 50% + 10px )' },
                transform: 'translateX(-50%)',
                backgroundColor: 'primary.main',
                color: 'primary.contrastText',
                borderRadius: 1,
                fontSize: 12,
                zIndex: theme => theme.zIndex.fab + 1,
                lineHeight: 1,
                pt: { xs: 0.3, md: 0.4 },
                pb: { xs: 0.2, md: 0.4 },
                px: { xs: 0.5, md: 1 },
                ...sx
            }}
            ref={ref}
        >
            {duration ? getStringDuration(duration) : null}
        </Typography>
    );
};

const NavCartTimerWithRef = forwardRef(NavCartTimer) as
    (props: NavCartTimerProps & { ref?: ForwardedRef<HTMLElement> }) => ReactElement;

const NavCartTimerGuard = ({ deadline, ...props }: NavCartTimerProps, ref: ForwardedRef<HTMLElement>) => {
    return (
        <>
            <Fade in={!!deadline} mountOnEnter={true} unmountOnExit={true}>
                <NavCartTimerWithRef deadline={deadline} {...props} ref={ref}/>
            </Fade>
        </>
    );
};
const NavCartTimerGuardWithRef = forwardRef(NavCartTimerGuard) as (p: NavCartTimerProps & { ref?: ForwardedRef<HTMLElement> }) => ReactElement;

export default NavCartTimerGuardWithRef;
export type {
    NavCartTimerProps
};
