import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fade from '@mui/material/Fade';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import CustomChip, { type CustomChipProps } from 'components/utils/CustomChip';
import useApi from 'hooks/useApi';
import useTranslation from 'hooks/useTranslation';
import {
    type ForwardedRef,
    forwardRef,
    type ReactElement,
    type SyntheticEvent,
    useCallback,
    useEffect,
    useMemo,
    useState
} from 'react';
import { defaultPagination, paginateUrl, type PaginationReturnType, type PaginationUrlProps } from 'services/api_base';
import { type FetchProps } from 'services/types';
import useSWR, { type SWRConfiguration } from 'swr';
import { useBoolean, useDebounce } from 'usehooks-ts';
import { uuid } from '../../utils/random';
import FormField, { type FormFieldProps } from './FVFormField';

interface OptionProps {
    id: string | number;
    name: string;

    [key: string | number]: any;
}

interface FormSearchSelectFieldProps extends FormFieldProps {
    onChange: any;
    onChangeBoolean?: any,
    onInputChange?: any;
    url?: string;
    fetcher?: (props: FetchProps) => Promise<any>;
    paginate?: (props: PaginationUrlProps) => string;
    forwardPaginateArgs?: boolean,
    SWRConfig?: SWRConfiguration;
    valueTransformer?: (prop: OptionProps) => OptionProps;
    optionTransformer?: (prop: OptionProps) => OptionProps;
    AutocompleteProps?: any;
    fixedOptions?: OptionProps[];
    clearable?: boolean;
    serverSide?: boolean;
    CustomChipProps?: CustomChipProps,
    placeholder?: string
    textNoSearch? : string,
    textNoMatchWithFilter? : string

}

const FormSearchSelectField = ({
    label,
    placeholder,
    name = uuid(),
    value,
    onChange,
    onChangeBoolean,
    onInputChange: customInputChange,
    url,
    fetcher: customFetcher,
    paginate = paginateUrl,
    forwardPaginateArgs,
    SWRConfig,
    valueTransformer = (prop) => prop,
    optionTransformer = (prop) => prop,
    register,
    AutocompleteProps: {
        multiple = true,
        hideTags = false,
        ...AutocompleteProps
    } = {},
    fixedOptions = [],
    loading,
    error,
    clearable,
    serverSide = true,
    CustomChipProps,
    textNoSearch = 'layout.table.body.no_search',
    textNoMatchWithFilter = 'layout.table.body.no_match_with_filter',
    ...props
}: FormSearchSelectFieldProps, ref: ForwardedRef<any>) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const [searchValueUrl, setSearchValueUrl] = useState<string>('');
    const debouncedSearchValue = useDebounce<string>(searchValue, 200);

    const masterValue = useMemo(() => {
        if ([
            '',
            undefined,
            null
        ].includes(value)) {
            return multiple ? [] : value;
        }
        if (multiple) {
            return (Array.isArray(value) ? value : [value]).map(valueTransformer);
        } else {
            return valueTransformer(value);
        }
    }, [
        multiple,
        value,
        valueTransformer
    ]);

    const open = useBoolean(false);
    const { t } = useTranslation();

    useEffect(() => {
        if (serverSide) {
            setSearchValueUrl(debouncedSearchValue);
        }
    }, [
        debouncedSearchValue,
        serverSide
    ]);

    const { getApi } = useApi();
    const fetcher = useCallback(
        ({ ...args }: FetchProps) => customFetcher ? customFetcher({
            url: paginate({ ...args }),
            ...(forwardPaginateArgs ? args : ({}))
        }) : getApi({
            url: paginate({ ...args }),
            ...(forwardPaginateArgs ? args : ({}))
        }),
        [paginate,
            customFetcher,
            forwardPaginateArgs,
            getApi
        ]
    );

    const { data: choices, isValidating } = useSWR<PaginationReturnType<any>>(
        { url, search: searchValueUrl },
        fetcher,
        {
            fallbackData: defaultPagination,
            revalidateOnMount: false,
            revalidateIfStale: true,
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            ...SWRConfig
        }
    );

    const handleInputChange = useCallback((event: any, newValue: string) => {
        if (event?.type === 'change') {
            setSearchValue(newValue);
            if (customInputChange) {
                customInputChange(newValue);
            }
        }
    }, [customInputChange]);

    const renderInput = useCallback(
        ({ loading: _, InputProps, ...params }: any) => {
            return (
                <FormField
                    placeholder={ placeholder || t('layout.form.actions.search')}
                    error={error}
                    name={name}
                    fullWidth={true}
                    autoFocus={open.value}
                    {...(label && label != '' ? { label: label } : null)}
                    InputLabelProps={{ focused: true }}
                    {...props}
                    {...InputProps}
                    {...params}
                    disabled={props?.readOnly || props?.disabled || params?.readOnly || params?.disabled }
                    sx={{
                        pr: '8px!important',
                        ...props?.sx
                    }}
                    startAdornment={
                        <>
                            {props.startAdornment ? (
                                <InputAdornment position={'start'} sx={{ color: 'inherit' }}>
                                    {props.startAdornment}
                                </InputAdornment>
                            ) : null}
                            {InputProps.startAdornment}
                        </>
                    }
                    endAdornment={
                        <InputAdornment position={'end'}>
                            <Stack
                                direction={'row'}
                                alignItems={'center'}
                                justifyContent={'flex-end'}
                                spacing={0.5}
                            >
                                <Fade in={isValidating || loading} mountOnEnter={true} unmountOnExit={true}>
                                    <CircularProgress
                                        color={'inherit'}
                                        size={20}
                                    />
                                </Fade>
                                <Fade in={!!InputProps?.endAdornment} mountOnEnter={true} unmountOnExit={true}>
                                    <Stack
                                        position={'relative'}
                                        sx={{
                                            ['& > *']: {
                                                position: 'relative',
                                                right: '0!important'
                                            }
                                        }}
                                    >{InputProps?.endAdornment}</Stack>
                                </Fade>
                                {props?.endAdornment}
                            </Stack>
                        </InputAdornment>
                    }
                />
            );
        },
        [
            t,
            error,
            name,
            props,
            label,
            isValidating,
            loading,
            open,
            placeholder
        ]
    );

    const isOptionEqualToValue = useCallback(
        (option: any, val: any) => {
            if ([0].includes(option?.id) || val == '') {
                return true;
            }
            return Array.isArray(val)
                ? [...(val || [])].map((v: any) => v?.id || v).includes(option.id)
                : ![
                    undefined,
                    '',
                    null
                ].includes(val.id) ? val.id == option.id : false;
        },
        []
    );

    const handleChange = useCallback(
        (
            event: SyntheticEvent,
            val: any,
            reason: string,
            details?: string
        ) => {
            const fakeEvent = {
                target: {
                    name: name,
                    value: Array.isArray(val) ? [...val] : val
                }
            };
            return onChange ? onChange(fakeEvent) : Promise.reject();
        },
        [
            onChange,
            name
        ]
    );

    const renderTags = useCallback(
        (tags: any, getTagProps: any, _: any) => {
            return (
                <>
                    <Box
                        sx={{
                            minWidth: '100%',
                            maxWidth: '100%',
                            display: 'flex',
                            flexWrap: 'wrap'
                        }}
                    >
                        {(Array.isArray(tags) ? tags : [tags]).map(
                            (option: OptionProps, index: number) => (
                                <Tooltip key={option?.id || index} title={option?.name || ''}>
                                    <CustomChip
                                        {...getTagProps({ index })}
                                        {...(option?.label || option?.name
                                            ? {
                                                label: option?.label || option?.name
                                            }
                                            : null)}
                                        {...CustomChipProps}
                                        disabled={props?.readOnly || props?.disabled }
                                    />
                                </Tooltip>
                            )
                        )}
                    </Box>
                </>
            );
        },
        [CustomChipProps, props?.disabled, props?.readOnly]
    );

    return (
        <>
            <Autocomplete
                multiple={multiple}
                open={open.value}
                onOpen={open.setTrue}
                onClose={open.setFalse}
                getOptionLabel={(option: OptionProps) =>
                    option?.label || option?.name || ''
                }
                isOptionEqualToValue={isOptionEqualToValue}
                clearIcon={clearable ? undefined : <></>}
                renderTags={!hideTags ? renderTags : () => <></>}
                style={{ width: '100%' }}
                renderInput={renderInput}
                inputValue={multiple == false ? undefined : searchValue}
                noOptionsText={
                    searchValue == ''
                        ? t(textNoSearch)
                        : t(textNoMatchWithFilter)
                }
                loadingText={t('layout.form.state.loading')}
                selectOnFocus={true}
                includeInputInList={true}
                error={error?.message || error}
                readOnly={props?.readOnly}
                disabled={props?.disabled}
                filterOptions={(options) => options} //!à laisser
                {...AutocompleteProps}
                onChange={handleChange}
                onInputChange={handleInputChange}
                loading={isValidating || searchValue !== searchValueUrl}
                options={[
                    ...fixedOptions,
                    ...(choices?.data || [])
                ].map(optionTransformer)}
                value={masterValue}
                ref={ref}
            />
        </>
    );
};

const FormSearchSelectFieldWithRef = forwardRef(FormSearchSelectField) as
    (props: FormSearchSelectFieldProps & { ref?: ForwardedRef<any> }) => ReactElement;

export default FormSearchSelectFieldWithRef;
export { FormSearchSelectFieldWithRef as FormSearchSelectField };
export type { FormSearchSelectFieldProps, OptionProps };

