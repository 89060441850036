import { useTheme } from '@mui/material/styles';

interface IconProps {
    size?: number | string;
    color: 'primary' | 'tertiary' | 'childPrimary' | 'childTertiary';
}

const DestinationIcon = ({ size, color, ...props }: IconProps) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 24}
            height={size || 24}
            viewBox="0 0 24 24"
            fill={theme.palette.primary.main}
            {...props}
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M20.82,2.87c-.16-.15-.39-.19-.59-.1-.11,.05-.2,.13-.25,.22-.92,.66-2.41,.9-3.71-.12-2.2-1.72-4.15-1.34-5.07-.99v1.2s.02,0,.02,0c.08-.05,2.01-1.17,4.37,.67,1.44,1.12,3.07,1.1,4.3,.55v4.06c-.92,.62-2.38,.81-3.63-.17-2.2-1.72-4.15-1.34-5.07-.99v1.2s.01,0,.02,0c.08-.05,2.01-1.18,4.37,.67,.83,.65,1.74,.91,2.59,.91,1,0,1.93-.37,2.6-.9,.07-.05,.13-.15,.17-.23,.03-.07,.04-.14,.04-.22V3.42s.01-.08,.01-.12c0-.16-.07-.32-.19-.43Z" fill={theme?.palette?.[color].main}/>
            <path d="M10.66,18.27c-.3,0-.55-.24-.55-.55V1.55c0-.3,.24-.55,.55-.55s.55,.24,.55,.55V17.72c0,.3-.24,.55-.55,.55Z"/>
            <path d="M10.75,23c-.3,0-.55-.24-.55-.55v-1.87c0-.3,.24-.55,.55-.55s.55,.24,.55,.55v1.87c0,.3-.24,.55-.55,.55Z"/>
            <path d="M7.1,18.32H3.53c-.3,0-.55-.24-.55-.55s.24-.55,.55-.55h3.56c.3,0,.55,.24,.55,.55s-.24,.55-.55,.55Z"/>
            <path d="M5.9,21.51c-.19,0-.38-.1-.48-.29-.14-.27-.04-.6,.22-.74l2.32-1.24c.26-.14,.6-.04,.74,.22,.14,.27,.04,.6-.22,.74l-2.32,1.24c-.08,.04-.17,.06-.26,.06Z"/>
            <path d="M17.78,18.33h-3.55c-.3,0-.55-.24-.55-.55s.24-.55,.55-.55h3.55c.3,0,.55,.24,.55,.55s-.24,.55-.55,.55Z"/>
            <path d="M15.72,21.51c-.09,0-.18-.02-.26-.06l-2.32-1.24c-.27-.14-.37-.47-.22-.74,.14-.27,.48-.37,.74-.22l2.32,1.24c.27,.14,.37,.47,.22,.74-.1,.18-.29,.29-.48,.29Z"/>
        </svg>
    );
};

export default DestinationIcon;
export { DestinationIcon };

