

import { useTheme } from '@mui/material/styles';

interface IconProps {
    size?: number | string;
}

const MyVadrouillesIcon = ({ size, ...props }: IconProps) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 30}
            height={size || 30}
            viewBox="0 0 15.86 15.23"
            fill={theme.palette.neutral.main}
            {...props}
        >
            <path d="M4.88,10.17l-1.01,2.01c-.16,.32-.54,.45-.84,.27-.11-.06-.2-.16-.26-.27l-1.56-3.1-.28-.55c-.08-.15-.15-.31-.21-.47l-.2-.52c-.11-.35-.17-.71-.17-1.08-.02-1.77,1.31-3.22,2.98-3.24,.1,0,.21,0,.31,.02" fill="none" stroke="#b03824" strokeLinecap="round" strokeMiterlimit="10" strokeWidth=".68px" />
            <path d="M15.28,5.17c.16,.39,.24,.83,.24,1.28,0,.19-.01,.37-.04,.56-.04,.21-.09,.42-.18,.62l-.23,.57h0c-.06,.16-.14,.32-.21,.47l-.1,.2-1.67,3.31c-.16,.32-.54,.45-.84,.27-.11-.06-.2-.16-.26-.27l-1.06-2.1" fill="none" stroke="#b03824" strokeLinecap="round" strokeMiterlimit="10" strokeWidth=".68px" />
            <path d="M12.16,3.25c.12-.02,.25-.03,.38-.03,.62,0,1.19,.21,1.67,.56" fill="none" stroke="#b03824" strokeLinecap="round" strokeMiterlimit="10" strokeWidth=".68px" />
            <g>
                <path d="M9.02,5.08c0,.37-.31,.67-.68,.67s-.68-.31-.68-.68,.31-.67,.68-.67,.68,.3,.68,.67h0" fill="#b03824" />
                <path d="M10.08,5.44l-1.5,4-1.5-3.37c-.1-.22-.24-.39-.43-.53-.19-.13-.44-.2-.74-.2h-1.04v.13l.43,.13,2.6,5.82h.26l.78-2.11h0l1.35-3.63,.02-.05c.06-.14,.07-.22,.07-.22,.04-.16,.05-.3,.05-.45,0-1.16-.95-2.1-2.12-2.1-.78,0-1.49,.42-1.87,1.1-.01,.03-.02,.04-.03,.06h.34c.33-.54,.92-.87,1.57-.87,1.01,0,1.83,.81,1.83,1.81,0,.14-.02,.28-.05,.42,0,0,0,.03-.02,.06Z" fill="#0d1a27" />
            </g>
            <path d="M11.24,2.01c.82,.9,1.31,2.13,1.3,3.49,0,.29-.02,.58-.07,.86-.06,.33-.15,.65-.27,.96l-.35,.88h0c-.1,.24-.21,.49-.33,.73l-.15,.31-2.58,5.13c-.25,.5-.84,.69-1.31,.42-.17-.1-.31-.24-.4-.42l-2.42-4.8-.43-.85c-.12-.24-.23-.48-.33-.73l-.32-.8c-.18-.54-.27-1.11-.27-1.67-.03-2.73,2.04-4.98,4.61-5.01,.61,0,1.19,.14,1.73,.37" fill="none" stroke="#0d1a27" strokeLinecap="round" strokeMiterlimit="10" strokeWidth=".68px" />
        </svg>
    );
};

export default MyVadrouillesIcon;
export { MyVadrouillesIcon };

