import { useTheme } from '@mui/material/styles';

interface IconProps {
    size?: number | string;
    color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'neutral'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'disabled';
    filled?: boolean;
}

const FiltersIcon = ({ size, color, filled, ...props }: IconProps) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 24}
            height={size || 24}
            viewBox="0 0 24 24"
            fill={
                color == 'inherit'
                    ? 'inherit'
                    : color
                        ? theme.palette[color].main
                        : theme.palette.primary.main
            }
            {...props}
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M4.26,22c-.29,0-.53-.24-.53-.53V2.53c0-.29,.24-.53,.53-.53s.53,.24,.53,.53V21.47c0,.29-.24,.53-.53,.53Z"/>
            <path d="M12.23,22c-.29,0-.53-.24-.53-.53V2.53c0-.29,.24-.53,.53-.53s.53,.24,.53,.53V21.47c0,.29-.24,.53-.53,.53Z"/>
            <path d="M19.74,22c-.29,0-.53-.24-.53-.53V2.53c0-.29,.24-.53,.53-.53s.53,.24,.53,.53V21.47c0,.29-.24,.53-.53,.53Z"/>
            <rect x="18.06" y="4.34" width="3.36" height="3.36" rx="1.6" ry="1.6" fill={filled ? undefined : theme.palette.childTertiary.main}/>
            <rect x="2.58" y="8.85" width="3.36" height="3.36" rx="1.6" ry="1.6" fill={filled ? undefined : theme.palette.childTertiary.main}/>
            <rect x="10.55" y="16.59" width="3.36" height="3.36" rx="1.6" ry="1.6" fill={filled ? undefined : theme.palette.childTertiary.main}/>
        </svg>
    );
};

export default FiltersIcon;
export { FiltersIcon };

