import { type EstablishmentProps } from './establishments_base';
import { type PoiProps } from './pois';

interface OrderItemLaguageProps {
    id: string;
    name: string;
    description: string;
    tribune: string;
    gamme: string;
    date?: string;
}
interface OrderItemProps {
    id: string;
    language: OrderItemLaguageProps;
    poi: PoiProps;
    ttc_unit_price: number;
}

interface EstablishmentOrderProps {
    id: number;
    order_id: number;
    establishment_id: number;
    establishment: EstablishmentProps;
    order_items: OrderItemProps[];
}

interface OrderProps {
    id?: number;
    order_number?: string;
    ttc_total?: number;
    date?: string;
    establishment_orders?: EstablishmentOrderProps[];
    phone: string;
    address: string;
    additional_address?: string;
    post_code: string;
    city: string;
    country?: string;
}

const defaultOrder: OrderProps = {
    id: 0,
    phone: '',
    address: '',
    post_code: '',
    city: '',
};

interface CartPreviewProps {
    id?: number;
    uuid?: string;
    expiration_date?: string;
    nbOrderItems?: number;
}

const defaultCartPreview: CartPreviewProps = {};

interface CartProps {
    id?: number;
    uuid?: string;
    expiration_date?: string;
    nbOrderItems?: number;
    establishment_orders: EstablishmentOrderProps[];
}

const defaultCart: CartProps = {
    id: 0,
    establishment_orders: []
};

export { defaultCart, defaultCartPreview, defaultOrder };
export type {
    CartPreviewProps,
    CartProps, EstablishmentOrderProps, OrderItemLaguageProps, OrderItemProps, OrderProps
};

