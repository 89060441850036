import AlertCard, { type AlertCardProps } from 'components/cards/AlertCard';
import { createContext, type ReactElement, useCallback, useMemo, useRef } from 'react';
import { useBoolean, useUpdateEffect } from 'usehooks-ts';

interface AlertContextProps {
    alert: (_: AlertCardProps) => void
}
const defaultAlertContext: AlertContextProps = {
    alert: () => null
};

const AlertContext = createContext<AlertContextProps>(defaultAlertContext);

interface AlertProviderProps {
    children: ReactElement
}

const AlertProvider = ({ children }: AlertProviderProps) => {
    const alertOpen = useBoolean(false);
    const alertProps = useRef<AlertCardProps | undefined>();


    useUpdateEffect(() => {
        if (!alertOpen.value) {
            alertProps.current = undefined;
        }
    }, [alertOpen]);


    const alert = useCallback(({ ...args }) => {
        alertProps.current = ({ ...args });
        alertOpen.setTrue();
    }, [alertOpen]);



    const contextValue = useMemo(() => ({
        alert
    }), [alert]);

    return (
        <AlertContext.Provider value={contextValue}>
            <AlertCard {...alertProps.current} open={alertOpen.value} onClose={alertOpen.setFalse}/>
            <>{children}</>
        </AlertContext.Provider>
    );
};

export default AlertProvider;
export {
    AlertProvider,
    AlertContext
};
export type {
    AlertProviderProps,
    AlertContextProps
};