import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle, { type DialogTitleProps } from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { type ReactElement } from 'react';


interface AlertTitleProps extends DialogTitleProps {
    onClose: () => void
}
const AlertTitle = ({ children, onClose, sx, ...props }: AlertTitleProps) => {
    return (
        <>
            <DialogTitle 
                sx={{ 
                    m: 0, 
                    p: 2,
                    minWidth: 200, 
                    ...sx 
                }} 
                {...props}
            >
                {children}
                <IconButton 
                    aria-label={'close'}
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>       
        
        </>
    );
};

interface AlertCardProps  {
    title?: string | ReactElement,
    text?: string | ReactElement,
    onClose?: () => void,
    [key: string]: any
}
const AlertCard = ({ children, title, text, onClose = () => null, sx, ...props }: AlertCardProps) => {

    return (
        <>
            <Dialog 
                open={true}
                maxWidth={'lg'}
                scroll={'paper'}
                onClose={onClose}

                {...props}
            >
                <AlertTitle onClose={onClose}>{title}</AlertTitle>
                <DialogContent
                    sx={{
                        px: 2,
                        py: 2,
                        ...sx
                    }}
                >
                    <Stack
                        px={2}
                        alignItems={'flex-start'}
                        justifyContent={'center'}
                    >
                        <>{text}</>
                        <>{children}</>
                    </Stack>
                </DialogContent>
            </Dialog>
        </>
    );
};


export default AlertCard;
export {
    AlertCard
};
export type {
    AlertCardProps
};

