import { type ForwardedRef, forwardRef, memo, type ReactElement } from 'react';
import Box, { type BoxProps } from '@mui/material/Box';
import Portal, { type PortalProps } from '@mui/material/Portal';


interface MainWrapperProps extends BoxProps {
    children?: ReactElement,
    PortalProps?: PortalProps
}

const MainWrapper = ({
    children,
    PortalProps,
    sx,
    ...props
}: MainWrapperProps, ref: ForwardedRef<HTMLElement>) => {
    return (
        <>
            <Portal disablePortal={true} {...PortalProps}>
                <Box
                    component={'main'}
                    sx={{
                        display: 'flex',
                        minHeight: '100%',
                        flex: 1,
                        width: '100%',
                        flexDirection: 'column',
                        ...sx
                    }}
                    {...props}
                    ref={ref}
                >
                    {children}
                </Box>
            </Portal>
        </>
    );
};

const MainWrapperWithRef = forwardRef(MainWrapper) as
    (props: MainWrapperProps & { ref?: ForwardedRef<HTMLElement> }) => ReactElement;

const MemoizedMainWrapperWithRef = memo(MainWrapperWithRef);

export default MemoizedMainWrapperWithRef;
export type {
    MainWrapperProps
};