import { useTheme } from '@mui/material/styles';

interface IconProps {
    size?: number | string;
    color?:
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'neutral'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'disabled'
    | 'childPrimary'
    | 'childSecondary'
    | 'childTertiary';
}

const MarkerIcon = ({ color, size, ...props }: IconProps) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 24}
            height={size || 24}
            viewBox="0 0 24 24"
            fill={
                color == 'inherit'
                    ? 'inherit'
                    : color
                        ? theme.palette[color]?.main
                        : theme.palette.primary.main
            }
            {...props}
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12.02,23h-.07c-1.19-.12-4.18-5.39-5.06-7-2.72-4.94-2.55-7.29-2.54-7.38,0-4.18,3.43-7.61,7.66-7.61s7.66,3.44,7.66,7.66c0,.05,.18,2.4-2.55,7.34-.88,1.59-3.83,6.8-5.01,6.98l-.09,.02Zm-.02-20.97c-3.66,0-6.63,2.97-6.63,6.63,0,.06-.12,2.23,2.42,6.84,2.25,4.08,3.71,5.95,4.22,6.39,.48-.41,1.94-2.28,4.2-6.39,2.54-4.61,2.42-6.78,2.42-6.8,0-3.7-2.98-6.67-6.63-6.67Z"/>
            <path d="M12.01,11.65c-1.58,0-2.86-1.28-2.86-2.86s1.28-2.86,2.86-2.86,2.86,1.28,2.86,2.86-1.28,2.86-2.86,2.86Zm0-4.7c-1.01,0-1.83,.82-1.83,1.83s.82,1.83,1.83,1.83,1.83-.82,1.83-1.83-.82-1.83-1.83-1.83Z"/>
        </svg>
    );
};

export default MarkerIcon;
export { MarkerIcon };

