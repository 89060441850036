import Box, { type BoxProps } from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import FormControl from '@mui/material/FormControl';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import NavSearchEstablishmentAdornement from 'components/menu/search/NavSearchEstablishmentAdornement';
import SearchBarEstablishmentButton from 'components/menu/search/SearchBarEstablishmentButton';
import SearchInput, { type SearchInputProps } from 'components/menu/search/SearchInput';
import useApi from 'hooks/useApi';
import useImmersivePage from 'hooks/useImmersivePage';
import useNavMenu from 'hooks/useMenu';
import dynamic from 'next/dynamic';
import { type SyntheticEvent, useCallback, useRef, useState, useTransition } from 'react';
import { API_URLS } from 'services/api';
import { type FetchProps } from 'services/types';
import { useElementSize, useIsomorphicLayoutEffect } from 'usehooks-ts';
import Portal from '@mui/material/Portal';

const FilterDrawer = dynamic(() => import('components/menu/filters/FilterDrawer'), { ssr: false });

interface NavSearchProps extends BoxProps {
    expended?: boolean,
    onClickAway?: () => void,
    SearchInputProps?: Partial<SearchInputProps>
    withSearchFilters: boolean,
    hidden?: boolean
}

const NavSearch = ({ expended = true, withSearchFilters, onClickAway, SearchInputProps, children, sx, hidden, ...props }: NavSearchProps) => {
    const [value, setValue] = useState('');
    const { filterOpen, setFilterOpen, search } = useNavMenu();

    const {
        logo,
        establishmentId
    } = useImmersivePage();

    const [_isPending, startTransition] = useTransition();
    const handleValueChange = useCallback((_event: SyntheticEvent, ...newValue: any[]) => {
        startTransition(() => {
            setValue('');
            search(newValue.reduce((a: any, c: any) => ({ ...a, ...c }), {}));
        });
    }, [search]);

    const handleClickAway = useCallback(() => {
        return onClickAway ? onClickAway() : null;
    }, [onClickAway]);

    const { postApi } = useApi();
    const fetcher = useCallback(({ url, lang, ...args }: FetchProps) => {
        return args?.search.length > 0 ? postApi({
            url,
            lang,
            body: {
                ...args,
                establishment_id: establishmentId
            }
        }).then(res => ({ data: res })) : Promise.resolve({ data: [] });
    }, [postApi, establishmentId]);

    const theme: Theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const wrapperRef = useRef<HTMLElement>(null);

    //Permet de remonter le champ de recherche sur mobile pour suivre l'affichage du clavier
    useIsomorphicLayoutEffect(() => {
        if (!isMobile) {return;}
        const handleResize = () => {
            if (!wrapperRef.current) {
                return;
            }
            wrapperRef.current.scrollIntoView({
                block: 'center',
                inline: 'nearest',
                behavior: 'smooth'
            });
        };
        if ('virtualKeyboard' in navigator) {
            // @ts-ignore
            navigator.virtualKeyboard.overlaysContent = true;
            // @ts-ignore
            navigator.virtualKeyboard.addEventListener('geometrychange', handleResize);
            return () => {
                // @ts-ignore
                navigator?.virtualKeyboard?.removeEventListener('geometrychange', handleResize);
            };
        }
    }, [isMobile]);

    const handleFilterClick = useCallback((event: SyntheticEvent) => {
        event.preventDefault();
        setFilterOpen();
    }, [setFilterOpen]);

    const handleFilterClose = useCallback((event: SyntheticEvent) => {
        event.preventDefault();
        setFilterOpen(false);
    }, [setFilterOpen]);

    const [searchRef, { width: searchWidth, height: searchHeight }] = useElementSize();
    const establishmentButtonRef = useRef<HTMLDivElement>(null);

    //TODO: recherche
    return hidden ? (
        <>
            <Box
                width={'100%'}
                sx={{
                    backgroundColor: 'transparent'
                }}
            />
        </>
    ) : (
        <>
            <Box
                width={'100%'}
                sx={{
                    backgroundColor: 'transparent'
                }}
            >
                <ClickAwayListener
                    onClickAway={handleClickAway}
                    mouseEvent={expended ? 'onClick' : false}
                    touchEvent={expended ? 'onTouchEnd' : false}
                >
                    <Box
                        sx={{
                            visibility: expended ? 'visible' : 'collapse',
                            width: '100%',
                            flexGrow: 1,
                            backgroundColor: 'none',
                            ...sx
                        }}
                        {...props}
                        ref={wrapperRef}
                    >
                        <FormControl
                            variant={'standard'}
                            fullWidth={true}
                            margin={'dense'}
                            sx={{
                                px: { xs: 1.25, md: 3 },
                                py: 1.25,
                                mt: 0,
                                mb: 0,
                                position: 'relative'
                            }}
                            focused={false}
                            ref={searchRef}
                        >
                            <SearchInput
                                url={establishmentId ? `${API_URLS.ESTABLISHMENTS}/${establishmentId}/search` : API_URLS.SEARCH}
                                fetcher={fetcher}
                                name={'frenchvadrouilles_search_bar'}
                                value={value}
                                loading={_isPending}
                                onChange={handleValueChange}
                                onFilterClick={handleFilterClick}
                                filtersAvailable={withSearchFilters}
                                {...SearchInputProps}
                                sx={{
                                    pl: establishmentId ? '0px!important' : { xs: '8px!important', md: '16px!important' },
                                    ['& input']: {
                                        pl: { xs: '8px!important', md: '16px!important' },
                                        pr: { xs: '8px!important', md: '16px!important' }
                                    },
                                    ['& .MuiInputAdornment-positionStart']: {
                                        mr: 0
                                    }
                                }}
                                {...(establishmentId ? ({
                                    startAdornment: (
                                        <NavSearchEstablishmentAdornement
                                            logo={logo}
                                            establishmentId={establishmentId}
                                            ref={establishmentButtonRef}
                                        />
                                    )
                                }) : null)}
                            />
                            {children}
                        </FormControl>

                        {establishmentId ? <Portal container={establishmentButtonRef?.current} disablePortal={false}>
                            <SearchBarEstablishmentButton
                                logo={logo}
                                establishmentId={establishmentId}
                                sx={{
                                    minWidth: 48
                                }}
                            />
                        </Portal> : <></>}

                        <Fade in={withSearchFilters} mountOnEnter={true} unmountOnExit={true}>
                            <Box>
                                <FilterDrawer
                                    open={filterOpen}
                                    onClose={handleFilterClose}
                                    mobile={isMobile}
                                    PaperProps={{
                                        sx: {
                                            width: '100%',
                                            borderBottomRightRadius: isMobile ? 0 : 4,
                                            borderBottomLeftRadius: isMobile ? 0 : 4,
                                            ...(!isMobile ? ({
                                                maxWidth: searchWidth
                                            }) : null)
                                        }
                                    }}
                                />
                            </Box>
                        </Fade>
                    </Box>
                </ClickAwayListener>
            </Box>
        </>
    );
};

export default NavSearch;
export type {
    NavSearchProps
};
