import { useTheme, type Theme } from '@mui/material/styles';
import { type SVGProps } from 'react';

interface LogoSmallProps extends SVGProps<SVGSVGElement> {
    extended?: boolean
    color?: 'white' | 'primary'
}
const LogoSmall = ({ extended, color, ...props }: LogoSmallProps) => {
    const theme: Theme = useTheme();
    
    const color1 = color == 'primary' ? theme.palette.primary.main : '#fff';
    const color2 = color == 'primary' ? theme.palette.tertiary.main : '#fff';

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
            <path d="M0,0H24V24H0V0Z" fill="none"/>
            <path d="m4.29,4.15v9.13s-1.09.27-1.09.27v.27h5.16v-.27l-1.09-.27v-5.4h1.84c.59,0,1.07.45,1.13,1.03h.47v-2.82h-.47c-.06.58-.55,1.03-1.13,1.03h-1.84V1.73h3.49c.53,0,1.02.27,1.3.72.22.35.24.66.18.95h.63V1h-6.71s-2.98,0-2.98,0v.4c.61.05,1.08.47,1.08.97v1.77Z" fill={color1}/>
            <path d="m16.36,23c.61-1.63,1.21-3.26,1.82-4.89l2.09-5.7s.69-1.61.5-2.97c-.3-2.08-1.99-3.75-4.21-3.75-1.56,0-2.93.82-3.66,2.04,0,0-.11.17-.2.44h.03s.74,0,.74,0c.62-1.07,1.77-1.79,3.09-1.79,1.97,0,3.56,1.6,3.56,3.58,0,.17-.01.33-.03.49,0,0-.08.56-.31,1.24l-2.6,7.09-.04.12-3.01-6.7c-.19-.43-.47-.77-.84-1.04-.37-.26-.85-.4-1.43-.4h-2.01v.25l.84.25c1.72,3.91,3.45,7.82,5.17,11.73h.51,0Z" fill={color1}/>
            <path d="m16.58,11.31c.65,0,1.18-.53,1.18-1.19s-.53-1.19-1.18-1.19-1.18.53-1.18,1.19.53,1.19,1.18,1.19Z" fill={color2}/>
        </svg>
    );
};

export default LogoSmall;
export {
    LogoSmall
};
export type {
    LogoSmallProps
};

