import Box from '@mui/material/Box';
import { type IconButtonProps } from '@mui/material/IconButton';
import Stack, { type StackProps } from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { type LinkProps } from 'components/menu/NavLink';
import { forwardRef, type ForwardedRef, type ReactElement } from 'react';
import NavCart from '../navbar/NavCart';
import NavAccount from './NavAccount';

interface NavAccountContainerProps extends LinkProps {
    StackProps?: Partial<StackProps>,
    showLabel?: boolean,
    color?: IconButtonProps['color']
}

const NavAccountContainer = ({ showLabel, StackProps, ...props }: NavAccountContainerProps, ref: ForwardedRef<HTMLElement>) => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Box
                sx={{
                    pl: { xs: 6, sm: 8 },
                    right: 0,
                    ml: { md: '16px!important' }
                }}
            >
                <Stack
                    direction={'row'}
                    alignItems={'center'}
                    justifyContent={'flex-end'}
                    spacing={{ xs: 1, sm: 2, md: 0 }}
                    {...StackProps}
                    sx={{
                        backgroundColor: 'childSecondary.main',
                        height: { xs: 60, md: 82 },
                        transform: { xs: 'translateY(-4px)', md: 'translateY(0)' },
                        ml: { xs: -6, sm: - 8 },
                        pl: 0,
                        pr: { xs: 0.625, md: 0 },
                        marginRight: 0,
                        borderRadius: '50px 0 0 50px',
                        ...StackProps?.sx
                    }}
                >
                    <NavAccount
                        mobile={mobile}
                        {...props}
                        ref={ref}
                    />
                    {!mobile ?
                        <NavCart />
                        :
                        null
                    }
                </Stack>
            </Box>
        </>
    );
};

const NavAccountContainerWithRef = forwardRef(NavAccountContainer) as
    (p: NavAccountContainerProps & { ref?: ForwardedRef<HTMLElement> }) => ReactElement;

export default NavAccountContainerWithRef;
export type {
    NavAccountContainerProps
};

