import { useTheme } from '@mui/material/styles';

interface IconProps {
    size?: number | string;
}

const DiscoverIcon = ({ size, ...props }: IconProps) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 24}
            height={size || 24}
            viewBox="0 0 24 24"
            fill={theme.palette.primary.main}
            {...props}
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12,23c-6.08,0-11-4.92-11-11S5.92,1,12,1s11,4.92,11,11c0,6.07-4.93,10.99-11,11m0-20.98C6.49,2.02,2.02,6.49,2.02,12s4.47,9.98,9.98,9.98,9.98-4.47,9.98-9.98h0c0-5.51-4.47-9.97-9.98-9.98" />
            <path d="M17.49,6.4c-.15-.14-.36-.18-.55-.11l-7.35,3.07c-.13,.06-.24,.16-.28,.3,0,.02-.02,.04-.02,.06l-2.77,7.44c-.1,.26,.03,.56,.3,.66,.06,.02,.12,.03,.18,.03,.07,0,.14-.01,.2-.04l7.32-3.16s.07-.05,.1-.09c.03-.02,.05-.03,.08-.05,0,0,0-.02,.01-.02s.01-.02,.02-.03c.07-.09,.11-.2,.11-.32l2.77-7.19c.07-.19,.03-.41-.12-.55m-7.49,4.32l3.43,3.29-5.54,2.39,2.11-5.68Zm4.1,2.51l-3.37-3.23,5.5-2.3-2.13,5.53Z" />
            <path d="M16.83,19.26c-.28,0-.51-.23-.51-.51,0-.17,.09-.33,.23-.43,2.81-1.84,4.14-5.27,3.3-8.52-.07-.27,.09-.55,.37-.62,.27-.07,.55,.09,.62,.37,.95,3.68-.55,7.56-3.73,9.64-.08,.05-.18,.08-.28,.08" fill={theme.palette.childTertiary.main} />
        </svg>
    );
};

export default DiscoverIcon;
export { DiscoverIcon };

