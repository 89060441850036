import IconButton, { type IconButtonProps } from '@mui/material/IconButton';
import FiltersIcons from 'components/icons/FiltersIcons';

interface FilterButtonProps extends IconButtonProps {
    open?: boolean
}

const FilterButton = ({ ...props }: FilterButtonProps) => {
    return (
        <>
            <IconButton
                disableFocusRipple={true}
                component={'span'}
                {...props}
            >
                <FiltersIcons/>
            </IconButton>
        </>
    );
};


export default FilterButton;
export type {
    FilterButtonProps
};