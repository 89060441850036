import NextImage, {
    type ImageLoaderProps as NextImageLoaderProps,
    type ImageProps as NextImageProps
} from 'next/image';
import { memo, useCallback, useId } from 'react';
import useSWR from 'swr';
import placeholderGeneralImage from '../../public/placeholder.webp';
import { useBoolean } from 'usehooks-ts';
import { blurDataURL } from './blurDataURL';

//TODO: image format opti
type CustomImageLoaderProps = NextImageLoaderProps;
const CustomImageLoader = ({ src, width, quality }: CustomImageLoaderProps) => {
    try {
        return (new URL(`${src}`)).href; //?w=${width}&q=${quality}
    } catch (_) {
        return '';
    }
};

interface CustomImageProps extends Partial<NextImageProps> {
    id?: string,
    initialLoading?: boolean,
    sx?: any,
    opt?: {
        escapeImageLib?: boolean
    },
    objectFit?: any,
    credits?: string,
    title?: string,
    [key: string]: any
}
const CustomImage = ({
    src: path = '',
    sx,
    opt = { escapeImageLib: false },
    placeholderImage = placeholderGeneralImage,
    objectFit = 'cover',
    url: _url,
    alt,
    onError,
    ...props
}: CustomImageProps) => {
    const hasLoaded = useBoolean(false);
    const { data: src, mutate } = useSWR<string | any>(path, (p) => {
        return (p && p != '') ? opt?.escapeImageLib ? `${p}` : `${process.env.NEXT_PUBLIC_REACT_IMAGE_URL}/${p}` : placeholderImage;
    }, {
        fallbackData: (path && path != '') ? opt?.escapeImageLib ? `${path}` : `${process.env.NEXT_PUBLIC_REACT_IMAGE_URL}/${path}` : placeholderImage,
        revalidateOnMount: true,
        revalidateIfStale: true,
        keepPreviousData: false
    });

    const handleError = useCallback((err: any) => {
        mutate(placeholderImage, { revalidate: false });
        hasLoaded.setTrue();
        return onError ? onError(err) : null;
    }, [onError, mutate, placeholderImage, hasLoaded]);

    const imageId = useId();

    return (
        <>
            <NextImage
                key={props?.key ? props.key : imageId}
                loader={CustomImageLoader}
                fill={true}
                placeholder={'blur'}
                blurDataURL={blurDataURL}
                unoptimized={true}
                alt={`${alt || ''}`}
                {...props}
                style={{
                    transition: 'filter .225s ease',
                    ...sx,
                    objectFit
                }}
                src={src}
                onError={handleError}
                onLoadingComplete={hasLoaded.setTrue}
            />
        </>
    );
};

const MemoizedCustomImage = memo(CustomImage);

export default MemoizedCustomImage;
export {
    MemoizedCustomImage as CustomImage,
    CustomImageLoader
};
export type {
    CustomImageProps,
    CustomImageLoaderProps
};

