import Chip, { type ChipProps } from '@mui/material/Chip';
import { type ForwardedRef, forwardRef, type ReactElement, useCallback } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Icon from '@mui/material/Icon';

interface CustomChipProps extends ChipProps {
    loading?: boolean
}
const CustomChip = ({ onDelete, loading, sx, ...props }: CustomChipProps, ref: ForwardedRef<HTMLDivElement>) => {
    const handleDelete = useCallback(({ ...args }) => {
        return onDelete ? onDelete({ ...args }) : Promise.resolve();
    }, [onDelete]);

    return (
        <>
            <Chip 
                variant={'outlined'}
                skipFocusWhenDisabled={true}
                size={'small'}
                sx={{
                    fontSize: '0.8rem',
                    ...sx
                }}
                {...props}
                {...(onDelete ? ({ onDelete: handleDelete }) : null)}
                {...(loading ? ({
                    icon: <>
                        <Icon color={'inherit'} fontSize={'small'} sx={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                            <CircularProgress size={'0.75rem'} disableShrink={true} color={'inherit'}/>
                        </Icon>
                    </>
                }) : null)}
                ref={ref}
            />

        </>
    );
};

const CustomChipWithRef = forwardRef(CustomChip) as
    (p: CustomChipProps & { ref?: ForwardedRef<HTMLDivElement> }) => ReactElement;


export default CustomChipWithRef;
export type {
    CustomChipProps
};

