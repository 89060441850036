import List, { type ListProps } from '@mui/material/List';
import { type Theme, useTheme } from '@mui/material/styles';
import { ForwardedRef, forwardRef, ReactElement } from 'react';

interface SearchListboxProps extends ListProps {
    test?: boolean
}

const SearchListbox = ({ ...props }: SearchListboxProps, ref: ForwardedRef<HTMLUListElement>) => {
    const theme: Theme = useTheme();

    return (
        <>
            <List
                role={'listbox'}
                sx={{
                    zIndex: theme.zIndex.tooltip
                }}
                {...props}
                ref={ref}
            />
        </>
    );
};


const SearchListboxWithRef = forwardRef(SearchListbox) as
    (p: SearchListboxProps & { ref?: ForwardedRef<HTMLUListElement> }) => ReactElement;


export default SearchListboxWithRef;
export {
    SearchListboxWithRef as SearchListbox
};
export type {
    SearchListboxProps
};