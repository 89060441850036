import { useTheme } from '@mui/material/styles';

interface IconProps {
    size?: number | string;
}

const GeolocationIcon = ({ size, ...props }: IconProps) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 24}
            height={size || 24}
            viewBox="0 0 24 24"
            fill={theme.palette.primary.main}
            {...props}
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M22.5,11.74h-1.66c-.14-4.58-3.77-8.29-8.32-8.55V1.52c0-.28-.22-.5-.5-.5s-.5,.22-.5,.5v1.67C6.97,3.45,3.34,7.16,3.2,11.74H1.5c-.28,0-.5,.22-.5,.5s.22,.5,.5,.5h1.73c.36,4.36,3.9,7.82,8.3,8.07v1.67c0,.28,.22,.5,.5,.5s.5-.22,.5-.5v-1.67c4.39-.25,7.93-3.71,8.3-8.07h1.68c.28,0,.5-.22,.5-.5s-.22-.5-.5-.5Zm-9.98,8.07v-1.62c0-.28-.22-.5-.5-.5s-.5,.22-.5,.5v1.62c-3.84-.24-6.94-3.26-7.3-7.07h1.56c.28,0,.5-.22,.5-.5s-.22-.5-.5-.5h-1.58c.13-4.03,3.32-7.29,7.32-7.55v1.62c0,.28,.22,.5,.5,.5s.5-.22,.5-.5v-1.62c4,.25,7.19,3.52,7.32,7.55h-1.63c-.28,0-.5,.22-.5,.5s.22,.5,.5,.5h1.61c-.36,3.81-3.46,6.83-7.3,7.07Z"/>
            <path d="M12.02,7.93c-2.25,0-4.07,1.83-4.07,4.07s1.83,4.07,4.07,4.07,4.07-1.83,4.07-4.07-1.83-4.07-4.07-4.07Zm0,7.14c-1.69,0-3.07-1.38-3.07-3.07s1.38-3.07,3.07-3.07,3.07,1.38,3.07,3.07-1.38,3.07-3.07,3.07Z"/>
        </svg>
    );
};

export default GeolocationIcon;
export { GeolocationIcon };

