import Box, { type BoxProps } from '@mui/material/Box';
import { type Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { type ForwardedRef, forwardRef, type ReactElement, useRef } from 'react';
import { navLinks } from './NavLinks';
import dynamic from 'next/dynamic';
import { type NavBarDesktopProps } from 'components/menu/navbar/desktop/NavBarDesktop';
import { type NavBarMobileProps } from 'components/menu/navbar/mobile/NavBarMobile';
import Stack from '@mui/material/Stack';
import Fade from '@mui/material/Fade';
import MainWrapper from 'components/layout/MainWrapper';
import { mergeRefs } from 'react-merge-refs';

const NavBarDesktop = dynamic(() => import('./desktop/NavBarDesktop')) ;
const NavBarMobile = dynamic(() => import('./mobile/NavBarMobile'));

type NavBarProps = Partial<(NavBarDesktopProps | NavBarMobileProps) & any>;

interface NavMenuProps extends Partial<BoxProps & NavBarProps> {
    children: ReactElement,
    headerType: 'search' | 'return' | 'returnItem' | 'none',
    withSearchFilters?: boolean,
    onReturn?: () => void,
    title?: string,
    text?: string
}

const NavMenu = ({ headerType, withSearchFilters = false, children, onReturn, title, text, ...props }: NavMenuProps, ref: ForwardedRef<any>) => {
    const theme: Theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('md'));
    const mainContentMobileRef = useRef(null);

    return (
        <>
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    maxWidth: '100vw',
                    flexDirection: 'column',
                    position: 'relative',
                    ...(mobile ? ({
                        // pb: `${navbarHeight}px`,
                        //pt: (headerType == 'search' || headerType == 'return') ? 8 : 0,
                        zIndex: theme.zIndex.appBar
                    }) : null)
                }}
            >
                <Box
                    component={mobile ? NavBarMobile : NavBarDesktop}
                    links={navLinks}
                    withSearchFilters={withSearchFilters}
                    {...(mobile ? ({
                        onReturn,
                        title,
                        text,
                        headerType
                    }) : null)}
                    {...props}
                >
                    <Fade in={mobile} timeout={0} mountOnEnter={true} unmountOnExit={true}>
                        <Stack flex={1}>

                            <MainWrapper
                                ref={ref}
                            >
                                {children}
                            </MainWrapper>
                        </Stack>
                    </Fade>
                </Box>

                <Fade in={!mobile} timeout={0} mountOnEnter={true} unmountOnExit={true}>
                    <Stack flex={1}>

                        <MainWrapper
                            ref={ref}
                        >
                            {children}
                        </MainWrapper>
                    </Stack>
                </Fade>
            </Box>
        </>
    );
};

const NavMenuWithRef = forwardRef(NavMenu) as
    (p: NavMenuProps & { ref?: ForwardedRef<any> }) => ReactElement;

const NavMenuWrapper = (props: NavMenuProps, outsideRef: ForwardedRef<unknown>) => {
    const ref = useRef(null);
    return (
        <NavMenuWithRef {...props} ref={mergeRefs([ref, outsideRef])}/>
    );
};

const NavMenuWrapperWithRef = forwardRef(NavMenuWrapper) as
    (p: NavMenuProps & { ref?: ForwardedRef<unknown> }) => ReactElement;

export default NavMenuWrapperWithRef;
export type {
    NavMenuProps
};

