import { type ForwardedRef, forwardRef, type ReactElement, useRef } from 'react';
import Box, { type BoxProps } from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import { mergeRefs } from 'react-merge-refs';
import Fade from '@mui/material/Fade';


interface NavSearchEstablishmentAdornementProps extends BoxProps {
    children?: ReactElement,
    establishmentId: number,
    logo?: any
}

const NavSearchEstablishmentAdornement = ({
    children,
    establishmentId,
    logo,
    ...props
}: NavSearchEstablishmentAdornementProps, ref: ForwardedRef<HTMLElement>) => {
    const establishmentButtonRef = useRef<HTMLButtonElement>(null);

    return (
        <>
            <Collapse in={!!(establishmentId && logo)} orientation={'horizontal'} appear={true} >
                <InputAdornment
                    position={'start'}
                    sx={{
                        height: 38,
                        maxHeight: 38,
                        minWidth: 48,
                        // maxWidth: 48,
                        borderRight: establishmentButtonRef?.current ? '1px solid rgba(0, 0, 0, 0.23)' : 'none'
                    }}
                >
                    <Stack
                        alignItems={'center'}
                        justifyContent={'center'}
                        ref={mergeRefs([establishmentButtonRef, ref])}
                    />
                </InputAdornment>
            </Collapse>
        </>
    );
};

const NavSearchEstablishmentAdornementWithRef = forwardRef(NavSearchEstablishmentAdornement) as
    (props: NavSearchEstablishmentAdornementProps & { ref?: ForwardedRef<HTMLElement> }) => ReactElement;

export default NavSearchEstablishmentAdornementWithRef;
export type {
    NavSearchEstablishmentAdornementProps
};