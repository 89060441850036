import NavMenu, { type NavMenuProps } from 'components/menu/navbar/NavMenu';
import { useRouter } from 'next/router';
import { createContext, type ReactElement, useCallback, useMemo, useRef, useState, useTransition } from 'react';
import { type QueryParamsType, type SearchProps } from 'services/search';
import createFastContext from 'shared/hooks/createFastContext';
import { pick } from 'shared/utils/pick';
import { useBoolean } from 'usehooks-ts';
import useImmersivePage from 'hooks/useImmersivePage';

type QueryParamKey = keyof QueryParamsType;
type QueryParamSubKey = keyof QueryParamsType[QueryParamKey];

interface QueryOptionsProps {
    //TODO: a implementer - non nécessaire pour l'instant
    unique?: boolean
}

const defaultQueryParams: QueryParamsType = {
    weather: {
        available_under_rain: false
    },
    activity: {
        vadrouilles: true,
        pois: true
    }
};

const { FastProvider, useFastStore } = createFastContext<QueryParamsType>(defaultQueryParams);

interface NavMenuContextProps {
    filterOpen: boolean,
    setFilterOpen: (value?: boolean) => void,
    search: (params?: any) => Promise<void | boolean>,
    matchType: (backendType: string) => string | undefined,
    overrideNavMenuProps: (args: Partial<NavMenuProps> | undefined) => void,
    resetNavMenuProps: (...removeKeys: string[]) => void
}

const defaultNavMenuContext: NavMenuContextProps = {
    filterOpen: false,
    setFilterOpen: () => null,
    search: () => Promise.reject(),
    matchType: (backendType) => backendType,
    overrideNavMenuProps: () => undefined,
    resetNavMenuProps: () => undefined
};
const NavMenuContext = createContext<NavMenuContextProps>(defaultNavMenuContext);

interface NavMenuProviderProps extends NavMenuProps {
    children: ReactElement,
    headerType: 'search' | 'return' | 'returnItem' | 'none',
    withSearchFilters?: boolean,
    onReturn?: () => void,
    initialQueryParams?: QueryParamsType
}

const NavMenuProvider = ({
    headerType,
    children,
    ...props
}: NavMenuProviderProps) => {
    const router = useRouter();
    const filterOpen = useBoolean(false);
    const expandTo = useRef();
    const { establishmentId } = useImmersivePage();

    const matchType = useCallback((backendType: string) => {
        switch ((backendType || '').toLowerCase()) {
            case 'city':
                return 'cities';
            case 'tag':
                return 'tags';
            case 'theme':
                return 'themes';
            case 'subtheme':
                return 'sub_themes';
            case 'frenchvadrouillestag':
                return 'frenchvadrouilles_tags';
            case 'destination':
                return 'destinations';
            case 'Establishment':
                return 'Establishment';
            default:
                return undefined;
                // return backendType;
        }
    }, []);

    const search = useCallback(async (params: SearchProps = {}) => {
        const { type, name, id, ...rest } = params;
        const matchedType = matchType(type);
        const eid = establishmentId ?? (matchedType === 'Establishment' && id);
        const newPathname = `/${eid ? 'establishments/[id]' : ''}${!(params?.type === 'Establishment') ? '/search' : '' }`;
        const replace = router.pathname === newPathname;

        const matchedValue = matchedType && router.query?.[matchedType]?.length
            ? (Array.isArray(router.query[matchedType])
                ? [...(router.query[matchedType] as Array<string>), params?.id]
                : [router.query[matchedType], params?.id])
            : [params?.id];

        const routerFn = replace ? router.replace : router.push;
        return routerFn({
            pathname: newPathname,
            query: {
                ...(eid ? ({ id: eid }) : null),
                ...(matchedType ?
                    ({ [matchedType]: matchedValue, ...rest }) :
                    rest
                )
            } as any
        }, undefined, { shallow: false, scroll: true });
    }, [establishmentId, router]);



    const [isPending, startTransition] = useTransition();
    const handleSetFilterOpen = useCallback((value?: boolean) => {
        startTransition(() => {
            filterOpen.setValue((oldValue) => (value != undefined) ? value : !oldValue);
        });
    }, []);

    const [overrideProps, setOverrideProps] = useState<Partial<NavMenuProps>>({});

    const overrideNavMenuProps = useCallback((args: Partial<NavMenuProps> = {}) => {
        startTransition(() => {
            setOverrideProps(state => ({ ...state, ...args }));
        });
    }, []);
    
    const resetNavMenuProps = useCallback((...removeKeys: string[]) => {
        if (removeKeys.length == 0) {setOverrideProps({});} else {
            setOverrideProps(state => {
                const filteredKeys = Object.keys(state).filter(key => !removeKeys.includes(key));
                const picked = pick(state, ...filteredKeys);
                return picked;
            });
        }
    }, []);

    const contextValue = useMemo(() => ({
        get filterOpen() {
            return filterOpen.value;
        },
        setFilterOpen: handleSetFilterOpen,
        search,
        matchType: matchType,
        overrideNavMenuProps,
        resetNavMenuProps
    }), [filterOpen, search, handleSetFilterOpen, matchType, overrideNavMenuProps, resetNavMenuProps]);

    return (
        <FastProvider>
            <NavMenuContext.Provider value={contextValue}>
                <>
                    <NavMenu
                        headerType={headerType}
                        {...props}
                        ref={expandTo}
                        {...overrideProps}
                    >
                        <>{children}</>
                    </NavMenu>
                </>
            </NavMenuContext.Provider>
        </FastProvider>
    );
};


export default NavMenuProvider;
export {
    NavMenuProvider,
    FastProvider,
    NavMenuContext,
    useFastStore as useQueryParams,
    defaultQueryParams
};
export type {
    NavMenuProviderProps,
    NavMenuContextProps,
    QueryParamKey,
    QueryParamsType as LocalQueryParamsType
};

