import Box, { type BoxProps } from '@mui/material/Box';
import { forwardRef, type ForwardedRef, type ReactElement } from 'react';

interface LinkOverlayProps extends Partial<BoxProps & any> {
    children?: ReactElement
}

const LinkOverlay = ({
    children,
    sx,
    ...props
}: LinkOverlayProps, ref: ForwardedRef<any>) => {
    return (
        <>
            <Box
                sx={{
                    position: 'relative',
                    ['&::before']: {
                        content: "''",
                        cursor: 'inherit',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        zIndex: 0,
                        width: '100%',
                        height: '100%',
                        pointerEvents: 'none'
                    },
                    ...sx
                }}
                {...props}
                ref={ref}
            >
                {children}
            </Box>
        </>
    );
};

const LinkOverlayWithRef = forwardRef(LinkOverlay) as
    (props: LinkOverlayProps & { ref?: ForwardedRef<any> }) => ReactElement;


export default LinkOverlayWithRef;
export type {
    LinkOverlayProps
};

