import FormCard from 'components/form/FVFormCard';
import useTranslation from 'hooks/useTranslation';
import { useCallback } from 'react';

interface CartChoiceDialogProps {
    open: boolean,
    onSubmit: (args: any) => Promise<void>
    onClose: (args: any) => Promise<void>
}

const CartChoiceDialog = ({ open, onSubmit, onClose }: CartChoiceDialogProps) => {
    const { t } = useTranslation();

    const handleSubmit = useCallback((selected : 'local' | 'account') => () => {
        if (selected !== undefined) {
            return onSubmit({ selected });
        } else {
            return Promise.reject();
        }
    }, [onSubmit]);

    return (
        <>
            <FormCard
                open={open}
                dialog={true}
                header={{
                    title: t('models.order.layout.cart_choice_dialog.title'),
                    subheader: t('models.order.layout.cart_choice_dialog.text')
                }}
                onClose={onClose}
                actions={{
                    return: {
                        variant: 'contained',
                        color: 'secondary',
                        text: t('layout.form.actions.yes'),
                        onClick: handleSubmit('local')
                    },
                    submit: {
                        variant: 'contained',
                        color: 'secondary',
                        text: t('layout.form.actions.no'),
                        onClick: handleSubmit('account')
                    }
                }}
            />
        </>
    );
};

export default CartChoiceDialog;
export {
    CartChoiceDialog
};

