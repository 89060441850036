import Badge from '@mui/material/Badge';
import Fab from '@mui/material/Fab';
import CartDesktopIcon from 'components/icons/CartDesktopIcon';
import useCart from 'hooks/useCart';
import { memo } from 'react';
import NavLink from 'shared/components/menu/NavLink';
import NavCartTimer from './NavCartTimer';

interface NavCartProps {
    variant?: 'desktop' | 'mobile'
}

const NavCart = ({ variant = 'desktop' }: NavCartProps) => {
    const { cartPreview, mutateCartPreview } = useCart();

    return (
        <NavLink
            href={'/cart'}
            passHref={true}
            sx={{
                //pt: { xs: 1.25, md: 0 },
                position: 'relative',
                ml: { md: '-16px!important' }
            }}
        >
            <Fab
                size={'small'}
                sx={{
                    position: 'relative',
                    width: { xs: 48, md: 110 },
                    height: { xs: 48, md: 68 },
                    backgroundColor: { xs: 'childSecondary.main', md: 'rgba(255,255,255,0.24)' },
                    color: 'childSecondary.contrastText',
                    borderRadius: { md: 0 },
                    zIndex: 0,
                    boxShadow: { md: 0 },
                    pl: { md: 2 },
                    ['&:hover']: {
                        backgroundColor: { md: 'rgba(255,255,255,0.12)' }
                    }
                }}
            >
                <Badge 
                    badgeContent={cartPreview ? cartPreview.nbOrderItems : null}
                    invisible={!cartPreview}
                    anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
                    color={'tertiary'}
                    sx={{
                        ['& .MuiBadge-badge']: {
                            fontSize: 12,
                            width: { xs: 16, md: 20 },
                            minWidth: 16,
                            height: { xs: 16, md: 20 },
                            p: 0,
                            flexWrap: 'nowrap',
                            fontWeight: 400,
                            top: { xs: -5, md: 0 },
                            right: { xs: -5, md: 0 }
                        },
                        ['& svg']: {
                            width: { md: 40 },
                            height: { md: 40 }
                        }
                    }}
                >
                    <CartDesktopIcon 
                        size={28}
                        color={'inherit'}
                    />
                </Badge>
                {cartPreview && cartPreview?.nbOrderItems && cartPreview?.expiration_date ? <NavCartTimer deadline={cartPreview.expiration_date} onTimerEnd={mutateCartPreview}/> : null}
            </Fab>
        </NavLink>
    );
};

const MemoizedNavCart = memo(NavCart);

export default MemoizedNavCart;
export type {
    NavCartProps
};

