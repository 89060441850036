import { AlertContext, type AlertContextProps } from 'contexts/AlertContext';
import { useContext } from 'react';

const useAlert = () => useContext(AlertContext) as AlertContextProps;

export default useAlert;
export {
    useAlert
};

