import { useTheme } from '@mui/material/styles';

interface IconProps {
    size?: number | string;
    color?:
    | 'inherit'
    | 'primary'
    | 'childPrimary'
    | 'secondary'
    | 'childSecondary'
    | 'tertiary'
    | 'childTertiary'
    | 'neutral'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | 'disabled';
}

const CartDesktopIcon = ({ size, color, ...props }: IconProps) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 24}
            height={size || 24}
            viewBox="0 0 24 24"
            fill={
                color == 'inherit'
                    ? 'currentColor'
                    : color
                        ? theme.palette[color].main
                        : theme.palette.primary.main
            }
            {...props}
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M20.28,16.79l2.34-8.25c.04-.14,.01-.3-.08-.42-.09-.12-.23-.19-.38-.19h-1.47c-.26,0-.48,.21-.48,.48s.21,.48,.48,.48h.84l-2.07,7.29H8.51l-2.21-7.29h1.23c.26,0,.48-.21,.48-.48s-.21-.48-.48-.48h-1.52l-.85-2.81c-.05-.16-.18-.29-.35-.33l-2.87-.67c-.27-.06-.52,.1-.58,.36-.06,.26,.1,.52,.36,.58l2.6,.61,3.99,13.18c.06,.2,.25,.34,.46,.34h11.24c.26,0,.48-.21,.48-.48s-.21-.48-.48-.48H9.13l-.33-1.08h11.02c.21,0,.4-.14,.46-.35Z"/>
            <path d="M10.17,11.89c.06,.24,.23,.44,.44,.56l.7,.39c.22,.12,.48,.16,.71,.09,.23-.06,.41-.21,.53-.41,.13-.23,.46-.29,.74-.14,.25,.14,.36,.43,.22,.69-.24,.42-.07,.97,.37,1.22l.7,.39c.14,.08,.31,.12,.47,.12,.08,0,.16-.01,.24-.03,.23-.06,.41-.21,.52-.41l5.17-9.13c.24-.42,.07-.97-.37-1.22l-.7-.39c-.44-.25-.99-.11-1.24,.32-.07,.13-.19,.18-.27,.21-.15,.04-.32,.02-.47-.07-.13-.07-.22-.18-.27-.32-.04-.11-.03-.23,.05-.37,.24-.42,.07-.97-.37-1.22l-.7-.39c-.22-.12-.48-.16-.71-.09-.22,.06-.41,.21-.52,.41l-5.17,9.13c-.11,.2-.14,.43-.08,.66Zm4.56-6.68s.08,.09,.14,.12l.18,.1c.07,.04,.15,.06,.23,.06,.17,0,.33-.09,.42-.24,.13-.23,.05-.52-.18-.65l-.18-.1c-.06-.03-.12-.05-.17-.06l1.02-1.82,.68,.35c-.19,.33-.22,.72-.1,1.09,.12,.36,.37,.66,.7,.85,.37,.21,.8,.27,1.2,.16,.37-.1,.67-.33,.78-.61l.7,.31-1.07,1.9c-.04-.06-.09-.12-.16-.16l-.18-.1c-.23-.13-.52-.05-.65,.18s-.05,.52,.18,.65l.18,.1c.07,.04,.14,.06,.22,.06l-3.62,6.45-.68-.35c.38-.67,.11-1.55-.6-1.95h0c-.25-.14-.53-.21-.79-.21-.5,0-.97,.24-1.18,.67l-.7-.31,3.65-6.5Z"/>
            <path d="M15.89,5.91l.34,.19c.08,.04,.16,.06,.24,.06,.17,0,.33-.09,.42-.24,.13-.23,.05-.52-.18-.65l-.34-.19c-.23-.13-.52-.05-.65,.18s-.05,.52,.18,.65Z"/>
            <path d="M17.08,6.58l.34,.19c.07,.04,.15,.06,.24,.06,.17,0,.33-.09,.42-.24,.13-.23,.05-.52-.18-.65l-.34-.19c-.23-.13-.52-.05-.65,.18-.13,.23-.05,.52,.18,.65Z"/>
            <path d="M8.92,12.9l.51,.13s.08,.02,.12,.02c.21,0,.41-.14,.46-.36,.07-.25-.09-.52-.34-.58l-.5-.05,1.85-7.22c.06,.04,.13,.08,.2,.09l.2,.03s.05,0,.08,0c.23,0,.43-.17,.47-.4,.04-.26-.13-.51-.4-.55l-.2-.03s-.1,0-.14,0l.52-2.03,.75,.17c-.19,.75,.29,1.52,1.08,1.72,.26,.07,.52-.09,.58-.34,.07-.26-.09-.52-.34-.58-.28-.07-.46-.32-.38-.61,.12-.47-.18-.96-.67-1.08l-.77-.2c-.49-.13-.99,.15-1.11,.62l-2.63,10.16c-.06,.22-.02,.46,.09,.66,.12,.21,.33,.37,.57,.43Z"/>
            <path d="M12.62,5.15s.05,0,.08,0c.23,0,.43-.17,.47-.4,.04-.26-.13-.51-.4-.55l-.2-.03c-.26-.04-.51,.13-.55,.4s.13,.51,.4,.55l.2,.03Z"/>
            <path d="M10.65,19.65c-.92,0-1.68,.75-1.68,1.68s.75,1.67,1.68,1.67,1.67-.75,1.67-1.67-.75-1.68-1.67-1.68Zm0,2.39c-.4,0-.72-.32-.72-.72s.32-.72,.72-.72,.72,.32,.72,.72-.32,.72-.72,.72Z"/>
            <path d="M18.58,19.65c-.92,0-1.68,.75-1.68,1.68s.75,1.67,1.68,1.67,1.67-.75,1.67-1.67-.75-1.68-1.67-1.68Zm0,2.39c-.4,0-.72-.32-.72-.72s.32-.72,.72-.72,.72,.32,.72,.72-.32,.72-.72,.72Z"/>
        </svg>
    );
};

export default CartDesktopIcon;
export { CartDesktopIcon };

