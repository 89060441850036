import Box, { type BoxProps } from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconButton from '@mui/material/IconButton';
import { type Theme } from '@mui/material/styles';
import dynamic from 'next/dynamic';
import NavLink from 'components/menu/NavLink';
import useApi from 'hooks/useApi';
import useTranslation from 'hooks/useTranslation';
import { useRouter } from 'next/router';
import {
    type ForwardedRef,
    forwardRef,
    memo,
    type MouseEvent,
    type ReactElement,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';
import { mergeRefs } from 'react-merge-refs';
import AvatarImage from 'shared/components/user/AvatarImage';
import { useElementSize } from 'usehooks-ts';

const NavAccountMenu = dynamic(() => import('./NavAccountMenu'), { ssr: false });

interface NavAccountProps extends BoxProps {
    avatarUrl?: string,
    mobile?: boolean,
    href?: string
}

const NavAccount = ({ mobile, href = '/login', ...props }: NavAccountProps, ref: ForwardedRef<HTMLElement>) => {
    const { user, logout, authenticated, loading } = useApi();
    const router = useRouter();
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | undefined>(undefined);
    const handleClick = useCallback((event: MouseEvent<HTMLElement>) => {
        if (loading) { return null; }
        if (authenticated) {
            return setAnchorEl(event.currentTarget);
        }
        return router.push(href);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated, loading, href]);
    const handleClose = useCallback(() => setAnchorEl(undefined), []);

    const handleSubmitLogout = useCallback(() => {
        handleClose();
        return logout();
    }, [logout, handleClose]);

    const handleClickAway = useCallback(() => {
        if (!mobile) {
            return handleClose();
        }
    }, [mobile, handleClose]);

    const actions = useMemo(() => ({
        account: {
            onClick: handleClose
        },
        logout: {
            onClick: handleSubmitLogout
        }
    }), [handleClose, handleSubmitLogout]);


    const [menuWrapperProps, setMenuWrapperProps] = useState<Partial<BoxProps & any> | undefined>({
        component: NavLink,
        href: '/login',
        passHref: true
    });

    // Permet d'éviter la perte de ref sur le parent
    useEffect(() => {
        setMenuWrapperProps((od) => authenticated ? undefined : ({
            component: NavLink,
            href: '/login',
            passHref: true
        }));
    }, [authenticated]);


    const containerRef = useRef();
    const [buttonRef, buttonSize] = useElementSize();

    return (
        <>
            <ClickAwayListener onClickAway={handleClickAway}>
                <Box 
                    mx={{ xs: 0, lg: 0 }} 
                    my={'auto'} 
                    textAlign={'end'} 
                    {...props} 
                    ref={mergeRefs([ref, containerRef])}
                    sx={{
                        position: { md: 'relative' },
                        zIndex: { md: 1 },
                        width: { md: 82 },
                        height: { md: 82 },
                        backgroundColor: 'childSecondary.main',
                        borderRadius: '50%'
                    }}
                >
                    <Box
                        {...menuWrapperProps}
                        ref={buttonRef}
                    >
                        <IconButton
                            {...(authenticated ? ({
                                ['aria-controls']: anchorEl ? 'account-menu' : undefined,
                                ['aria-haspopup']: 'true',
                                ['aria-expanded']: anchorEl ? 'true' : undefined,
                                onClick: handleClick
                            }) : ({

                            }))}
                            disabled={loading}
                            sx={{
                                height: { sm: 60, md: 82 },
                                width: { md: 82 },
                                px: { sm: '7px', md: '15px' },
                                py: { sm: '7px', md: '15px' },
                                backgroundColor: { md: 'childSecondary.main' },
                                ['&:hover']: {
                                    backgroundColor: { md: 'childSecondary.main' } 
                                },
                                ['&:hover .MuiAvatar-root']: {
                                    transform: 'scale(1.01)'  
                                }
                            }}
                        >
                            <AvatarImage
                                src={user?.profile_picture || ''}
                                AvatarProps={{
                                    sx: {
                                        borderRadius: 'auto',
                                        borderWidth: 2,
                                        borderColor: (theme: Theme) => !authenticated ? theme.palette.neutral.main : theme.palette.childSecondary.main,
                                        backgroundColor: (theme: Theme) => !authenticated ? theme.palette.childSecondary.main : theme.palette.neutral.main,
                                        borderStyle: 'solid',
                                        boxSizing: 'border-box',
                                        width: { xs: 46, md: 50 },
                                        height: { xs: 46, md: 50 },
                                        position: 'relative',
                                        transition: 'all 200ms linear',
                                        ['&:before']: {
                                            content: '""',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%,-50%)',
                                            width: { xs: 38, md: 40 },
                                            height: { xs: 38, md: 40 },
                                            borderRadius: '50%',
                                            backgroundColor: 'rgba(255, 255, 255, 0.4)'
                                        }
                                    }
                                }}
                                alt={t('models.user.data.avatar')}
                                priority={true}
                            />
                        </IconButton>
                    </Box>
                </Box>
            </ClickAwayListener>

            {authenticated ? <NavAccountMenu
                anchorEl={anchorEl}
                open={!!anchorEl}
                mobile={mobile}
                onClose={handleClose}
                data={user}
                actions={actions}
                buttonSize={buttonSize}
            /> : <></>}
        </>
    );
};

const NavAccountWithRef = forwardRef(NavAccount) as
    (p: NavAccountProps & { ref?: ForwardedRef<HTMLElement> }) => ReactElement;

const MemoizedNavAccountWithRef = memo(NavAccountWithRef);

export default MemoizedNavAccountWithRef;
export type {
    NavAccountProps
};

