import Avatar, { type AvatarProps } from '@mui/material/Avatar';
import CustomImage from 'components/images/CustomImage';
import NavLink from 'components/menu/NavLink';
import useTranslation from 'hooks/useTranslation';
import { type ForwardedRef, forwardRef, type ReactElement } from 'react';

interface SearchBarEstablishmentButtonProps extends Partial<AvatarProps> {
    establishmentId?: number,
    logo?: string,
}

const SearchBarEstablishmentButton = ({
    logo,
    establishmentId,
    sx,
    ...props
}: SearchBarEstablishmentButtonProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { t } = useTranslation();
    return (
        <>
            <NavLink
                href={{
                    pathname: '/establishments/[id]',
                    query: { id: establishmentId }
                }}
                passHref={false}
            >
                <Avatar
                    {...props}
                    sx={{
                        position: 'relative',
                        py: 0.75,
                        px: { xs: 1.25/*0.75*/, sm: 2 },
                        height: 48,
                        width: { xs: 56, md: 96 },
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderTopLeftRadius: 12,
                        borderBottomLeftRadius: 12,
                        background: 'transparent',
                        ...sx
                    }}
                    ref={ref}
                >
                    <CustomImage
                        src={logo}
                        placeholder={'empty'}
                        objectFit={'contain'}
                        alt={t('layout.form.actions.establishment_button')}
                    />
                </Avatar>
            </NavLink>
        </>
    );
};

const SearchBarEstablishmentButtonWithRef = forwardRef(SearchBarEstablishmentButton) as
    (p: SearchBarEstablishmentButtonProps & { ref?: ForwardedRef<HTMLDivElement> }) => ReactElement;

export default SearchBarEstablishmentButtonWithRef;
export type {
    SearchBarEstablishmentButtonProps
};

