import { useTheme } from '@mui/material/styles';

interface IconProps {
    size?: number | string;
    color?: string
}

const FavoriteIcon = ({ size, ...props }: IconProps) => {
    const theme = useTheme();

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size || 24}
            height={size || 24}
            viewBox="0 0 24 24"
            fill={theme.palette.primary.main}
            {...props}
        >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12.1,22.1h-.19c-.1,0-.2-.03-.29-.09l-1.33-.88c-2.01-1.32-4.29-2.81-6.07-4.92C2.03,13.61,.97,10.93,1,8.03c.03-3.38,2.75-6.14,6.06-6.14,2.29,0,3.92,1.16,4.94,2.25,1.02-1.1,2.64-2.25,4.94-2.25,1.11,0,2.2,.31,3.15,.91,.24,.15,.32,.47,.16,.71-.15,.24-.47,.32-.71,.16-.79-.49-1.68-.75-2.6-.75-2.13,0-3.6,1.21-4.45,2.22-.1,.12-.24,.18-.4,.18h-.19c-.15,0-.3-.07-.4-.18-.85-1.01-2.31-2.22-4.45-2.22-2.74,0-5,2.29-5.03,5.11-.02,2.65,.95,5.1,2.98,7.5,1.68,1.99,3.89,3.44,5.84,4.73l1.15,.76,1.15-.76c1.95-1.28,4.16-2.73,5.84-4.73,2.03-2.4,3-4.86,2.98-7.5,0-.83-.22-1.66-.6-2.39-.13-.25-.04-.57,.22-.7,.25-.13,.57-.04,.7,.22,.46,.88,.71,1.87,.72,2.86,.03,2.9-1.03,5.58-3.22,8.18-1.78,2.11-4.06,3.6-6.07,4.92l-1.33,.88c-.09,.06-.19,.09-.29,.09Z" />
            <path d="M20.18,9.08c-.25,0-.45-.2-.45-.44-.01-1.75-1.31-2.53-1.37-2.56-.21-.12-.29-.4-.16-.61,.12-.21,.39-.29,.61-.17,.07,.04,1.8,1.05,1.82,3.33,0,.25-.2,.45-.44,.45h0Z" fill={theme.palette.childTertiary.main}/>
        </svg>
    );
};

export default FavoriteIcon;
export { FavoriteIcon };

