import CustomImage, { type CustomImageProps } from 'components/images/CustomImage';
import Avatar, { type AvatarProps } from '@mui/material/Avatar';
import useTranslation from 'hooks/useTranslation';

interface AvatarImageProps extends CustomImageProps {
    AvatarProps?: AvatarProps
}
const AvatarImage = ({ src, AvatarProps, ...props }: AvatarImageProps) => {
    const { t } = useTranslation();

    return (
        <Avatar
            alt={t('models.user.data.avatar')}
            {...AvatarProps}
            sx={{
                ...(src ? ({ backgroundColor: 'white' }) : null),
                ...AvatarProps?.sx
            }}
        >
            {src ? (
                <CustomImage
                    src={src}
                    alt={t('models.user.data.avatar')}
                    {...props}
                />
            ) : undefined}

        </Avatar>
    );
};

export default AvatarImage;
export type {
    AvatarImageProps
};