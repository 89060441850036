import { useTheme, type Theme } from '@mui/material/styles';
import { type SVGProps } from 'react';

interface LogoProps extends SVGProps<SVGSVGElement> {
    extended?: boolean
    color?: 'white' | 'primary'
}
const Logo = ({ extended, color, ...props }: LogoProps) => {
    const theme: Theme = useTheme();
    
    const color1 = color == 'primary' ? theme.palette.primary.main : '#fff';
    const color2 = color == 'primary' ? '#AF3924' : '#b2b2b2';

    return extended ?  (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 283 75"  {...props}>
                <rect width="283" height="75" fill="none"/>
                <path d="m40.16,34.05l2.99-.75V1.49l-2.99-.74v-.75h26.44v9h-1.48c-.65-.54-1.21-1.2-1.63-1.94-1.04-1.76-1.58-3.77-1.57-5.82h-10.63v15.66h5.69c.22-1.24.66-2.44,1.29-3.53.26-.51.58-.99.94-1.44h1.48v11.18h-1.48c-.36-.45-.67-.93-.94-1.44-.63-1.09-1.06-2.29-1.29-3.53h-5.69v15.16l2.99.75v.74h-14.1v-.74Z" fill={color1}/>
                <path d="m68.32,34.05l2.99-.75V11.43l-2.99-.74v-.75h4.42c1.73-.09,3.42.61,4.59,1.89.46.55.82,1.17,1.09,1.84.51-.75,1.12-1.42,1.82-2,1.56-1.29,3.52-2,5.54-2,1.42-.11,2.82.33,3.93,1.22.92.92,1.39,2.2,1.31,3.5.14,1.92-1.31,3.59-3.22,3.73-.16.01-.32.01-.49,0-.46,0-.92-.03-1.38-.1l-.6-.15v-7c-1.74.03-3.39.75-4.59,2-.6.6-1.14,1.27-1.58,2v18.43l2.99.75v.74h-13.83v-.74Z" fill={color1}/>
                <path d="m96.79,32c-2.34-2.33-3.51-5.55-3.52-9.66-.05-2.42.47-4.82,1.53-7,.95-1.91,2.44-3.49,4.27-4.57,1.92-1.08,4.09-1.63,6.3-1.59,2.15-.04,4.29.43,6.23,1.36,1.69.79,3.12,2.04,4.15,3.6.97,1.49,1.48,3.22,1.46,5,.01.72-.07,1.43-.25,2.13l-.25.85c-1.41.3-2.99.55-4.59.74-3.47.5-6.97.75-10.47.75,0,3.84.52,6.59,1.57,8.23.9,1.57,2.59,2.51,4.39,2.46,2.5,0,4.83-1.26,6.2-3.36.62-.91.97-1.99,1-3.1h1.24c0,1.35-.42,2.66-1.19,3.77-.83,1.23-1.98,2.22-3.32,2.86-1.53.74-3.22,1.11-4.92,1.07-4.22,0-7.5-1.18-9.84-3.54Zm10.02-9.91c.6-.04,1.2-.12,1.78-.25,0-.17.11-.53.25-1.09.16-.87.24-1.75.24-2.64.13-2.06-.24-4.12-1.09-6-.45-1.08-1.46-1.82-2.62-1.91-2.47,0-3.7,4.06-3.7,12.18,1.71.02,3.43-.07,5.13-.26v-.03Z" fill={color1}/>
                <path d="m120.94,34.05l2.99-.75V11.43l-2.99-.74v-.75h4.45c1.74-.09,3.42.61,4.59,1.89.46.55.82,1.17,1.09,1.84.43-.82.98-1.58,1.63-2.24,1.46-1.48,3.46-2.29,5.53-2.23,1.73-.03,3.44.37,4.98,1.16,1.38.71,2.56,1.77,3.43,3.06.8,1.18,1.23,2.57,1.24,4v15.88l2.99.75v.74h-6.43c-2.99,0-4.44-1.49-4.44-4.47v-12.92c.1-1.67-.29-3.34-1.12-4.8-.51-.97-1.5-1.6-2.59-1.66-1.38.07-2.63.82-3.36,2-.45.61-.82,1.29-1.1,2v18.36l2.99.75v.74h-13.89v-.74Z" fill={color1}/>
                <path d="m157.31,32c-2.34-2.33-3.51-5.55-3.51-9.66s1.17-7.33,3.51-9.67c2.34-2.34,5.62-3.51,9.84-3.5,3.03,0,5.31.53,6.85,1.59,1.38.8,2.26,2.27,2.3,3.87.12,1.93-1.34,3.6-3.26,3.73-.15,0-.3,0-.44,0-.46,0-.93-.03-1.39-.1l-.6-.15v-7.42l-.69-.25c-.58-.18-1.18-.26-1.78-.25-1.85-.02-3.55,1.02-4.37,2.68-1.04,1.79-1.55,4.96-1.55,9.5s.52,7.5,1.55,9.27c.84,1.65,2.53,2.68,4.37,2.66,1.25,0,2.49-.31,3.59-.92,1.08-.58,1.99-1.41,2.66-2.44.62-.91.97-1.99,1-3.1h1.24c-.02,1.35-.45,2.67-1.24,3.77-.83,1.23-1.97,2.22-3.31,2.86-1.52.74-3.2,1.1-4.89,1.07-4.24,0-7.53-1.18-9.86-3.54Z" fill={color1}/>
                <path d="m179.99,34.05l2.99-.75V1.49l-2.99-.74v-.75h6.43c2.99,0,4.44,1.49,4.44,4.47v8c.43-.6.95-1.14,1.53-1.59,1.39-1.11,3.12-1.7,4.9-1.68,1.73-.03,3.44.37,4.98,1.16,1.39.71,2.57,1.76,3.44,3.06.8,1.18,1.23,2.57,1.24,4v15.88l2.99.75v.74h-6.43c-2.99,0-4.44-1.49-4.44-4.47v-12.92c.1-1.67-.29-3.34-1.12-4.8-.51-.97-1.5-1.6-2.59-1.66-1.38.07-2.63.82-3.36,2-.46.61-.83,1.28-1.1,2v18.36l2.99.75v.74h-13.91v-.74Z" fill={color1}/>
                <path d="m36.07,73.11c-1.52-1.13-2.4-2.92-2.37-4.82,0-1.32.29-2.63.84-3.83.22-.58.52-1.13.89-1.64,1.36-.33,2.82-.63,4.39-.89,3.13-.55,6.28-.91,9.45-1.1v-1.49c0-3.68-.45-6.21-1.34-7.6-.89-1.38-2.45-2.18-4.09-2.09-.6-.01-1.2.07-1.77.25l-.7.25v7.45l-.64.15c-.44.06-.89.1-1.34.1-1.92.13-3.58-1.32-3.71-3.24-.01-.16-.01-.32,0-.49.05-1.6.91-3.06,2.29-3.87,1.53-1.06,3.82-1.59,6.85-1.59,2.28-.06,4.55.39,6.63,1.34,1.76.82,3.24,2.13,4.25,3.78.99,1.68,1.49,3.59,1.45,5.54v13.44l2.99.75v.74h-4.47c-1.73.1-3.41-.57-4.59-1.84-.49-.54-.86-1.18-1.09-1.88-.45.81-1,1.56-1.63,2.23-1.45,1.48-3.45,2.29-5.52,2.24-2.94,0-5.2-.63-6.78-1.89Zm12.06-1.84c.43-.63.81-1.3,1.14-2v-7.2c-1.45,0-2.91.08-4.35.24-1,.14-1.71.22-2.07.25-.2.48-.37.98-.5,1.49-.32,1.22-.49,2.48-.49,3.74-.09,1.43.2,2.85.84,4.13.42.81,1.24,1.33,2.15,1.34,1.35-.09,2.58-.83,3.29-1.99Z" fill={color1}/>
                <path d="m93.74,73.51l2.99-.75v-21.87l-2.99-.74v-.75h4.44c1.74-.09,3.42.61,4.59,1.89.46.55.82,1.17,1.09,1.84.51-.75,1.13-1.42,1.83-2,1.55-1.29,3.51-2,5.53-2,1.42-.11,2.82.33,3.93,1.22.92.92,1.4,2.2,1.32,3.5.12,1.93-1.34,3.6-3.26,3.73-.15,0-.3,0-.44,0-.46,0-.93-.03-1.39-.1l-.6-.15v-7c-1.74.03-3.39.75-4.59,2-.6.6-1.13,1.27-1.57,2v18.43l2.99.75v.74h-13.86v-.74Z" fill={color1}/>
                <path d="m122.17,71.49c-2.33-2.33-3.48-5.55-3.48-9.66,0-4.11,1.17-7.34,3.48-9.67,2.33-2.34,5.53-3.51,9.62-3.5s7.29,1.17,9.61,3.5c2.33,2.34,3.49,5.56,3.49,9.67s-1.16,7.33-3.49,9.66c-2.32,2.34-5.52,3.51-9.61,3.51s-7.29-1.17-9.62-3.51Zm12.95-.27c.91-1.85,1.36-5,1.36-9.39s-.45-7.54-1.36-9.4-1.99-2.78-3.34-2.78-2.43.93-3.34,2.78-1.36,5-1.36,9.4.45,7.54,1.36,9.39,1.99,2.79,3.34,2.79,2.43-.93,3.34-2.79Z" fill={color1}/>
                <path d="m155.72,73.83c-1.39-.71-2.57-1.76-3.43-3.06-.8-1.18-1.24-2.57-1.24-4v-15.88l-2.99-.74v-.75h6.43c2.96,0,4.44,1.49,4.44,4.47v12.93c-.11,1.67.28,3.34,1.12,4.79.51.98,1.49,1.61,2.59,1.67,1.36-.08,2.6-.83,3.31-2,.44-.63.82-1.3,1.14-2v-18.37l-2.99-.74v-.75h13.83v.75l-2.99.74v23.61h-.5c-2.03.14-4.02-.58-5.48-2-.54-.57-.95-1.25-1.19-2-.45.81-1,1.56-1.63,2.23-1.43,1.47-3.4,2.29-5.45,2.27-1.73.03-3.44-.37-4.97-1.17Z" fill={color1}/>
                <path d="m180.95,73.51l2.99-.75v-21.87l-2.99-.74v-.75h6.39c2.96,0,4.44,1.49,4.44,4.47v18.89l2.99.75v.74h-13.82v-.74Zm3.99-28.08c-1.33-1.4-1.33-3.6,0-5,1.39-1.35,3.59-1.35,4.98,0,1.39,1.32,1.44,3.53.12,4.92-.67.71-1.62,1.1-2.59,1.08-.95.05-1.87-.32-2.54-1h.03Z" fill={color1}/>
                <path d="m197.75,73.51l2.99-.75v-31.76l-2.99-.74v-.75h6.43c2.96,0,4.44,1.49,4.44,4.47v28.78l2.99.75v.74h-13.86v-.74Z" fill={color1}/>
                <path d="m214.55,73.4l2.99-.86v-36.54l-2.99-.86v-.85h6.43c2.96,0,4.44,1.71,4.44,5.14v33.11l2.99.86v.85h-13.86v-.85Z" fill={color1}/>
                <path d="m234.86,71.49c-2.34-2.33-3.51-5.55-3.51-9.66-.06-2.42.47-4.83,1.53-7,.94-1.92,2.43-3.51,4.27-4.58,1.92-1.08,4.09-1.63,6.3-1.59,2.16-.05,4.3.41,6.25,1.34,1.69.8,3.12,2.04,4.16,3.6.96,1.49,1.47,3.23,1.45,5,.02.72-.07,1.43-.25,2.13l-.24.85c-1.42.3-2.99.55-4.6.74-3.47.5-6.97.75-10.47.75,0,3.84.53,6.59,1.58,8.23.9,1.55,2.56,2.49,4.34,2.46,2.5,0,4.84-1.26,6.21-3.36.62-.91.97-1.99,1-3.1h1.22c0,1.35-.42,2.66-1.19,3.77-.83,1.23-1.97,2.22-3.31,2.86-1.53.74-3.21,1.11-4.91,1.07-4.21,0-7.49-1.17-9.84-3.51Zm10.03-9.91c.6-.04,1.19-.12,1.77-.25l.25-1.09c.16-.87.23-1.76.21-2.64.13-2.06-.24-4.12-1.09-6-.45-1.08-1.46-1.82-2.62-1.91-2.47,0-3.71,4.06-3.71,12.18,1.73.01,3.46-.09,5.18-.29h0Z" fill={color1}/>
                <path d="m265.15,73.66c-.7-.37-1.35-.84-1.92-1.39-.46.68-1.05,1.27-1.73,1.73-.32.21-.65.4-1,.55h-1.24v-8.5h1.99c.42,1.38,1.06,2.69,1.88,3.88,1.75,2.55,3.92,3.82,6.52,3.83,1.14.09,2.26-.27,3.14-1,.76-.8,1.15-1.89,1.07-3,0-1.11-.56-2.14-1.47-2.76-1.45-1.03-2.97-1.96-4.56-2.76-1.48-.72-2.93-1.53-4.32-2.41-1.1-.71-2.06-1.61-2.82-2.68-.78-1.11-1.18-2.44-1.16-3.8-.02-1.94.94-3.75,2.55-4.83,1.69-1.24,4.3-1.86,7.83-1.86,1.71-.01,3.39.47,4.84,1.39.67.39,1.29.83,1.87,1.34.48-.65,1.07-1.22,1.73-1.69.56-.33.89-.51,1-.55h1.24v8.45h-1.99c-.46-1.34-1.07-2.62-1.83-3.82-1.65-2.59-3.61-3.89-5.88-3.88-.87-.01-1.69.35-2.27,1-.63.67-.97,1.57-.94,2.49,0,1.24.58,2.42,1.58,3.15,1.49,1.12,3.09,2.09,4.77,2.9,1.74.9,3.14,1.68,4.18,2.36,1.03.68,1.93,1.52,2.67,2.51.75,1,1.14,2.23,1.13,3.48,0,4.81-3.79,7.21-11.37,7.21-1.91.03-3.8-.43-5.48-1.34Z" fill={color1}/>
                <path d="m68.04,73.48c-1.6-1.12-2.86-2.67-3.61-4.48-.95-2.27-1.4-4.72-1.34-7.18,0-4.24.98-7.5,2.94-9.77,2-2.29,4.94-3.55,7.97-3.4,1.45-.03,2.88.31,4.16,1,.55.27,1.06.61,1.52,1v-9.65l-2.99-.74v-.75h6.41c2.97,0,4.45,1.49,4.45,4.47v28.78l2.99.75v.74h-4.47c-1.73.1-3.41-.57-4.59-1.84-.5-.54-.87-1.18-1.09-1.88-.44.82-1.01,1.58-1.67,2.23-1.45,1.47-3.43,2.28-5.49,2.24-1.84.03-3.65-.5-5.19-1.52Zm10.47-2.21c.44-.63.82-1.3,1.14-2v-17.14c-.35-.36-.73-.69-1.14-1-.73-.6-1.63-.95-2.57-1-1.41,0-2.51.83-3.28,2.48s-1.17,4.72-1.17,9.2.36,7.25,1.07,8.92,1.58,2.51,2.64,2.51c1.36-.07,2.59-.81,3.31-1.97Z" fill={color1}/>
                <g className="picto">
                    <path d="m30.89,41.05l-12.12,33.05h-1.47L2.47,40.3l-2.47-.74v-.75h5.98c1.47-.05,2.93.37,4.16,1.19,1.07.76,1.93,1.8,2.47,3l8.74,20,8.06-22s3.99-10.83-6.44-14.36c-4.73-1.79-10.06.11-12.61,4.5h-1.66s4.58-9.22,15.08-5.58c10.65,3.69,7.11,15.49,7.11,15.49Z" fill={color1}/>
                    <ellipse cx="19.43" cy="37" rx="3.48" ry="3.49" fill={color2}/>
                </g>
            </svg>
        </>
    ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="50" viewBox="0 0 32 50" {...props}>
            <path d="M31.43,16.55,19.11,50H17.6L2.51,15.79,0,15v-.76H6a7.2,7.2,0,0,1,4.28,1.19,7.47,7.47,0,0,1,2.51,3.09l8.9,20.27,8.2-22.28s4-11-6.55-14.53a10.53,10.53,0,0,0-12.83,4.5H8.85A12.25,12.25,0,0,1,24.2.86C35,4.6,31.43,16.55,31.43,16.55Z" fill={color1}/>
            <circle cx="19.78" cy="12.44" r="3.54" fill={color2}/>
        </svg>

    );
};

export default Logo;
export {
    Logo
};
export type {
    LogoProps
};

