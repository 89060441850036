import CartChoiceDialog from 'components/layout/CartChoiceDialog';
import useApi from 'hooks/useApi';
import { useSelectedLanguage } from 'hooks/useTranslation';
import { createContext, type ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { API_URLS } from 'services/api';
import { type CartPreviewProps, defaultCartPreview } from 'shared/services/order';
import useSWR, { type KeyedMutator } from 'swr';
import { useBoolean, useLocalStorage } from 'usehooks-ts';


interface CartContextProps {
    cartUuid?: string
    cartPreview?: CartPreviewProps,
    setCardUuid?: () => void | null
    cardUrl: { url: string; } | null,
    mutateCartPreview: KeyedMutator<CartPreviewProps | undefined>,
    addItemToCart: (args: any) => Promise<any>
}

const defaultCartContext: CartContextProps = {
    addItemToCart(args: any): Promise<any> {
        return Promise.resolve(undefined);
    },
    cardUrl: null,
    mutateCartPreview: Promise.reject
};

const CartContext = createContext<CartContextProps>(defaultCartContext);

interface CartProviderProps {
    children: ReactElement | ReactElement[]
}
const CartProvider = ({ children }: CartProviderProps) => {
    const { getApi, authenticated, loading, postApi } = useApi();
    const { lang } = useSelectedLanguage();

    const [storeCartUuid, setStoreCartUuid] = useLocalStorage<CartContextProps['cartUuid']>('cartUuid', undefined);
    const [cartUuid, setCartUuid] = useState<CartContextProps['cartUuid']>(storeCartUuid);

    useEffect(() => {
        setCartUuid(storeCartUuid);
    }, [storeCartUuid]);

    const getCardUrl = useCallback((preview?: boolean) => () => {
        let url = '';
        if (preview) {
            url = '/preview';
        }
        if (authenticated && !loading) {
            return { url: API_URLS.CART + url, lang, storeCartUuid };
        } else if (!loading && cartUuid) {
            return { url: API_URLS.CART + '/' + cartUuid + url, lang };
        } else {
            return null;
        }
    }, [authenticated, loading, cartUuid, lang, storeCartUuid]);

    const {
        data: cartPreview,
        mutate,
        error
    } = useSWR<CartPreviewProps | undefined>(getCardUrl(true), getApi, {
        fallbackData: defaultCartPreview,
        revalidateOnMount: true,
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: true,
        refreshInterval : 60000,
        onError: err => {
            if (err.statusCode == 404) {
                setStoreCartUuid(undefined);
            }
        }
    });

    const handleSetCartUuid = useCallback((uuid?: string) => (uuid) ? setStoreCartUuid(uuid) : null, [setStoreCartUuid]);

    const isOpenCartChoiceDialog = useBoolean(false);

    const checkLinkCart = useCallback(() => {
        if (authenticated && storeCartUuid) {
            return getApi({
                url: `${API_URLS.CART}/${cartUuid}/link`
            })
                .then((res) => {
                    setStoreCartUuid(undefined);
                    return Promise.resolve({});
                })
                .catch((res) => {
                    if (res.error == 'cart_already_existing') {
                        isOpenCartChoiceDialog.setTrue();
                    } else {
                        setStoreCartUuid(undefined);
                        //TODO (pour plus tard) trouver une solution pour afficher que le panier n'a pas été trouvé ?
                    }

                    return Promise.reject({});
                });
        }
        return Promise.resolve({});
    }, [authenticated, storeCartUuid, cartUuid, getApi, isOpenCartChoiceDialog, setStoreCartUuid]);

    const { data } = useSWR<CartPreviewProps | undefined>({ url: authenticated }, checkLinkCart, {
        fallbackData: defaultCartPreview,
        revalidateOnMount: true,
        revalidateIfStale: true,
        revalidateOnFocus: false,
        revalidateOnReconnect: true
    });

    const onSubmitCartChoiceDialog = useCallback(({ ...args }) => {
        if (args.selected == 'account') {
            setStoreCartUuid(undefined);
            isOpenCartChoiceDialog.setFalse();
            return Promise.resolve();
        } else {
            return getApi({
                url: `${API_URLS.CART}/${cartUuid}/link?option=update`
            })
                .then(() => {
                    setStoreCartUuid(undefined);
                    isOpenCartChoiceDialog.setFalse();
                    mutate();
                    return Promise.resolve();
                })
                .catch(() => {
                    isOpenCartChoiceDialog.setFalse();
                    return Promise.reject();
                    //TODO (pour plus tard) trouver une solution pour afficher que le panier n'a pas été lié ?
                });
        }

    }, [isOpenCartChoiceDialog, cartUuid, getApi, mutate, setStoreCartUuid]);

    const onCloseCartChoiceDialog = useCallback((args: any) => {
        isOpenCartChoiceDialog.setFalse();
        return Promise.resolve();
    }, [isOpenCartChoiceDialog]);

    const addItemToCart = useCallback(({ date, gamme, period, prestation, tribune }: any) => {
        return postApi({ url: `${API_URLS.CART}${!authenticated ? `/${cartUuid || 'new'}` : ''}/items`, body: {
            welogin_product_id: prestation.id,
            tickets: Object.entries(gamme)
                .map(([gammeId, { qqt: gammeQuantity, price: gammePrice = 0 }]: any) => {
                    return [...Array(gammeQuantity)].map(() => {
                        return ({
                            'gamme_id': gammeId,
                            'tribune_id': tribune.id,
                            ...(period ? ({
                                'date_id': period.id
                            }) : null),
                            'ttc_price': gammePrice
                        });
                    });
                })
                .reduce((a, c) => [...a, ...c], [])
        } });
    }, [postApi, cartUuid, authenticated]);

    const addItemToCartWrapper = useCallback(async (args: any) => {
        return addItemToCart(args)
            .then(({ uuid, tickets }) => {
                if (!authenticated && uuid) { setStoreCartUuid(uuid); }
                return tickets;
            })
            .finally(() => {
                mutate();
            });
    }, [addItemToCart, setStoreCartUuid, authenticated, mutate]);

    const contextValue = useMemo(() => ({
        cartPreview: error ? defaultCartPreview : cartPreview,
        setCardUuid: handleSetCartUuid,
        cardUrl: getCardUrl()(),
        mutateCartPreview: mutate,
        addItemToCart: addItemToCartWrapper
    }), [error, cartPreview, handleSetCartUuid, getCardUrl, mutate, addItemToCartWrapper]);

    return (
        <CartContext.Provider value={contextValue}>
            {children}
            <CartChoiceDialog
                open={isOpenCartChoiceDialog.value}
                onSubmit={onSubmitCartChoiceDialog}
                onClose={onCloseCartChoiceDialog}
            />
        </CartContext.Provider>
    );
};

export default CartProvider;
export {
    CartContext
};
export type {
    CartProviderProps,
    CartContextProps
};

