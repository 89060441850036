import Box, { type BoxProps } from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Logo from 'components/icons/Logo';
import LogoSmall from 'components/icons/LogoSmall';
import NavLink from 'components/menu/NavLink';
import useImmersivePage from 'hooks/useImmersivePage';

interface NavLogoProps extends BoxProps { 
    extended?: boolean,
    innerProps?: BoxProps,
    iconProps?: any
}
const NavLogo = ({ extended, iconProps, innerProps, ...props }: NavLogoProps) => {
    const { establishmentId } = useImmersivePage();

    return (
        <>
            <NavLink
                href={'/'}
                passHref={true}
                sx={{
                    py: establishmentId ? 0 : { xs: 1.25, sm: 1.75 },
                    px: establishmentId ? 0 : { xs: 1.25, sm: 2, xl: 3 },
                    width: { md: '100%' },
                    minHeight: { xs: 68, md: 80 },
                    cursor: 'pointer'
                }} 
                BoxProps={props}
            >
                <Box
                    component={IconButton}
                    width={ establishmentId ? { xs: 46, md: 72 } :  '100%' }
                    height={ establishmentId ? '100%' : { xs:'40px', sm: '40px', md: '52px' }}
                    textAlign={'center'}
                    color={'white'}
                    disableRipple={true}
                    size={'small'}
                    sx={{
                        borderRadius: establishmentId ? 0 : null,
                        pl: establishmentId ? { xs: 0.5, md: 2 } : null,
                        pr: establishmentId ? { xs: 0, md: 0 } : null,
                        py: establishmentId ? { xs: 1.5, md: 1.5 } : null
                    }}
                >   
                    { establishmentId ?
                        <LogoSmall 
                            color={'primary'} 
                            style={{ 
                                position: 'relative', 
                                width: '100%',
                                height: 'auto'
                            }}
                        />
                        :
                        <Logo extended={extended} color={'primary'} style={{ position: 'relative', width: 'auto', height: '100%' }}/>
                    }
                </Box>
            </NavLink>
        </>
    );
};

export default NavLogo;
export {
    NavLogo
};

